import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { DataGrid } from '@mui/x-data-grid';
import {Container, Grid, Card, CardContent, TextField, Typography, IconButton} from '@mui/material';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';
import config from "../system/config";
import '../HeaderGrid.css';
import ReactECharts from 'echarts-for-react';
import LinearProgress from '@mui/material/LinearProgress';
import DeleteIcon from "@mui/icons-material/Delete";
import Button from "@mui/material/Button";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import PaidIcon from '@mui/icons-material/Paid';
import ProductionQuantityLimitsIcon from '@mui/icons-material/ProductionQuantityLimits';
import IngresoModal from '../components/modalingreso';
import EgresoModal from '../components/modalegreso';
import VentaProductoCantidadDiaChart from '../components/VentaProductoCantidadDiaChart';

import 'dayjs/locale/es';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
dayjs.extend(utc); // Usa el plugin utc
dayjs.extend(timezone); // Usa el plugin timezone
dayjs.locale('es'); // Establece el locale de dayjs a español

export default function IngresosEgresos() {
   /* const fullname = localStorage.getItem('fullname');
    const userId = localStorage.getItem('userId');
    const userManager = localStorage.getItem('userManager');*/
    const userRole = Number(localStorage.getItem('userRole'));


    const [chartVentasModalOpen, setchartVentasModalOpen] = useState(false);
    const [startDate, setStartDate] = useState(dayjs().tz(`${config.timezonewislla}`));
    const [endDate, setEndDate] = useState(dayjs().tz(`${config.timezonewislla}`));
    const [ingresos, setIngresos] = useState([]);
    const [egresos, setEgresos] = useState([]);
    const [gaugeData, setGaugeData] = useState({ total: 0, ingresos: 0, egresos: 0, disponible: 0 });
    const [saldoEnEfectivo, setSaldoEnEfectivo] = useState(0);

    // Calcula el valor y valor máximo para el ProgressBar basándote en tu lógica de negocio
    const progressValue = Number(saldoEnEfectivo).toFixed(1); // este es solo un ejemplo, ajusta según tu necesidad
    const progressMax = Number(saldoEnEfectivo) * 1.5; // este es solo un ejemplo, ajusta según tu necesidad

    const [ingresoModalOpen, setIngresoModalOpen] = useState(false);
    const [egresoModalOpen, setEgresoModalOpen] = useState(false);
    const [openDialog, setOpenDialog] = useState(false);
    const [selectedId, setSelectedId] = useState(null);

    const [openEgresoDialog, setOpenEgresoDialog] = useState(false);
    const [selectedEgresoId, setSelectedEgresoId] = useState(null);
    const [searchIngresosTerm, setSearchIngresosTerm] = useState('');
    const [searchEgresosTerm, setSearchEgresosTerm] = useState('');


    // Filtrar los ingresos según el término de búsqueda
    const ingresosFiltrados = ingresos.filter((ingreso) => {
        return (
            ingreso.id.toString().toLowerCase().includes(searchIngresosTerm.toLowerCase()) ||
            ingreso.tipo.toLowerCase().includes(searchIngresosTerm.toLowerCase()) ||
            ingreso.monto.toString().toLowerCase().includes(searchIngresosTerm.toLowerCase()) ||
            ingreso.detalle.toLowerCase().includes(searchIngresosTerm.toLowerCase()) ||
            ingreso.fecha.toLowerCase().includes(searchIngresosTerm.toLowerCase())
        );
    });

// Filtrar los egresos según el término de búsqueda
    const egresosFiltrados = egresos.filter((egreso) => {
        return (
            egreso.id.toString().toLowerCase().includes(searchEgresosTerm.toLowerCase()) ||
            egreso.tipo.toLowerCase().includes(searchEgresosTerm.toLowerCase()) ||
            egreso.monto.toString().toLowerCase().includes(searchEgresosTerm.toLowerCase()) ||
            egreso.detalle.toLowerCase().includes(searchEgresosTerm.toLowerCase()) ||
            egreso.fecha.toLowerCase().includes(searchEgresosTerm.toLowerCase()) ||
            egreso.categoria.toLowerCase().includes(searchEgresosTerm.toLowerCase())
        );
    });

    const fetchData = async () => {
        try {
            // Obtener datos de ingresos y egresos
            const ingresosData = await axios.get(`${config.baseURL}/getIngresos`, {
                params: { inicio: startDate.format('YYYY-MM-DD'), fin: endDate.format('YYYY-MM-DD') }
            });
            const egresosData = await axios.get(`${config.baseURL}/getEgresos`, {
                params: { inicio: startDate.format('YYYY-MM-DD'), fin: endDate.format('YYYY-MM-DD') }
            });

            // Actualizar estados de ingresos y egresos
            setIngresos(ingresosData.data);
            setEgresos(egresosData.data);

            // Aquí también debes actualizar gaugeData, haciendo llamadas a la API o cualquier otro cálculo
            // Por ejemplo total ventas:
            const totalVenta = await axios.get(`${config.baseURL}/ventaintervalo`, {
                params: { inicio: startDate.format('YYYY-MM-DD'), fin: endDate.format('YYYY-MM-DD') }
            });

            const apiSaldoEnEfectivo = await axios.get(`${config.baseURL}/entrega`, {
                params: { inicio: startDate.format('YYYY-MM-DD'), fin: endDate.format('YYYY-MM-DD') }
            });

            // console.log('apiSaldoEnEfectivo', apiSaldoEnEfectivo);

            const totalIngresos = ingresosData.data
                .filter(item => item.tipo !== 'Saldo inicial')
                .reduce((acc, item) => acc + item.monto, 0);
            const totalEgresos = egresosData.data.reduce((acc, item) => acc + item.monto, 0);
            const disponible = totalVenta.data[0].total - totalEgresos;

            const totalVentaValue = totalVenta.data && totalVenta.data[0] && totalVenta.data[0].total ? totalVenta.data[0].total : 0;
            const totalIngresosValue = totalIngresos ? totalIngresos : 0;
            const totalEgresosValue = totalEgresos ? totalEgresos : 0;
            const disponibleValue = disponible ? disponible : 0;



            setSaldoEnEfectivo(apiSaldoEnEfectivo.data.entrega || 0);



            setGaugeData({
                total: parseFloat(totalVentaValue.toFixed(1)),
                ingresos: parseFloat(totalIngresosValue.toFixed(1)),
                egresos: parseFloat(totalEgresosValue.toFixed(1)),
                disponible: parseFloat(disponibleValue.toFixed(1))
            });
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };

    useEffect(() => {
        fetchData();
    }, [startDate, endDate]);

    const handleOpenChartModal = () => {
        setchartVentasModalOpen(true);
    };
    const gaugeOption = (title, value, isEgreso) => ({
        title: { text: title },
        series: [
            {
                type: 'gauge',
                min: 0,
                max:3000,
                axisLine: {
                    lineStyle: {
                        width: 12,
                        color: isEgreso
                            ? [
                                [0.18, '#67e0e3'],
                                [0.7, '#37a2da'],
                                [1, '#fd666d']
                            ]
                            : [
                                [0.18, '#fd666d'],
                                [0.7, '#37a2da'],
                                [1, '#67e0e3']
                            ],
                    }
                },
                pointer: {
                    width: 8,
                    itemStyle: {
                        color: 'inherit'
                    }
                },
                axisTick: {
                    distance: -5,
                    length: 4,
                    lineStyle: {
                        color: '#fff',
                        width: 0.5
                    }
                },
                splitLine: {
                    distance: -10,
                    length: 10,
                    lineStyle: {
                        color: '#fff',
                        width: 2
                    }
                },
                axisLabel: {
                    color: 'inherit',
                    distance: -35,
                    fontSize: 12
                },
                detail: {
                    valueAnimation: true,
                    formatter: 's/. {value}',
                    color: 'inherit',
                    fontSize: 20,
                    offsetCenter: [0, '70%']

                },
                title: {
                    offsetCenter: ['0%', '40%'],
                    color:'#fff',
                    fontSize:12
                },
                data: [{ value: value }],

            }

        ]
    });


    const handleCreateIngreso = async (newIngreso) => {
        try {

            // console.log('newIngreso', newIngreso)
            await axios.post(`${config.baseURL}/ingreso`, newIngreso);
            // Refresca los datos después de crear un nuevo ingreso
            fetchData();
        } catch (error) {
            console.error('Error creating Ingreso:', error);
        }
    };


    const handleDeleteIngreso = async (id) => {
        try {
            await axios.post(`${config.baseURL}/eliminaIngreso`, { id, userid:localStorage.getItem('userId') });
            // Refresca los datos después de eliminar un ingreso
            fetchData();
        } catch (error) {
            console.error('Error deleting Ingreso:', error);
        }
    };

    const handleCreateEgreso = async (newEgreso) => {
        try {
            // console.log('newEgreso', newEgreso)

            await axios.post(`${config.baseURL}/egreso`, newEgreso);
            // Refresca los datos después de crear un nuevo egresor
            fetchData();
        } catch (error) {
            console.error('Error creating Egreso:', error);
        }
    };

    const handleDeleteEgreso = async (id) => {
        try {
            await axios.post(`${config.baseURL}/eliminaEgreso`, { id, userid:localStorage.getItem('userId') });
            // Refresca los datos después de eliminar un egreso
            fetchData();
        } catch (error) {
            console.error('Error deleting Egreso:', error);
        }
    };

    return (
        <LocalizationProvider dateAdapter={AdapterDayjs}>
            <Dialog
                open={chartVentasModalOpen}
                onClose={() => setchartVentasModalOpen(false)}
                PaperProps={{
                    sx: {
                        width: '90%',
                        maxWidth: '1600px'
                    },
                }}
            >
                <DialogTitle>Ventas de Producto por Día</DialogTitle>
                <DialogContent>
                    <VentaProductoCantidadDiaChart />
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setchartVentasModalOpen(false)} color="primary">
                        Cerrar
                    </Button>
                </DialogActions>
            </Dialog>
            <Dialog
                open={openDialog}
                onClose={() => setOpenDialog(false)}
            >
                <DialogTitle>{"¿Eliminar Ingreso?"}</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        ¿Estás seguro de que quieres eliminar este ingreso?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setOpenDialog(false)} color="primary">
                        Cancelar
                    </Button>
                    <Button
                        onClick={async () => {
                            setOpenDialog(false);
                            try {
                                if (selectedId) {
                                    await handleDeleteIngreso(selectedId);
                                    toast.success('¡Ingreso eliminado con éxito!');
                                }
                            } catch (error) {
                                toast.error('Error eliminando el ingreso');
                            }
                        }}
                        color="primary"
                    >
                        Eliminar
                    </Button>
                </DialogActions>
            </Dialog>


            <Dialog
                open={openEgresoDialog}
                onClose={() => setOpenEgresoDialog(false)}
            >
                <DialogTitle>{"¿Eliminar Egreso?"}</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        ¿Estás seguro de que quieres eliminar este egreso?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setOpenEgresoDialog(false)} color="primary">
                        Cancelar
                    </Button>
                    <Button
                        onClick={async () => {
                            setOpenEgresoDialog(false);
                            if (selectedEgresoId) {
                                try {
                                    await handleDeleteEgreso(selectedEgresoId);
                                    toast.success('¡Egreso eliminado con éxito!');
                                } catch (error) {
                                    toast.error('Error eliminando el egreso');
                                }
                            }
                        }}
                        color="primary"
                    >
                        Eliminar
                    </Button>
                </DialogActions>
            </Dialog>

            <Container sx={{ maxWidth: '94% !important', p: 0, margin: '0 auto' }}>

                <Grid container spacing={1} sx={{ marginTop: '0.3rem', marginBottom: '0.1rem' }}>


                    {/* Date Pickers */}
                    <Grid item xs={6} sm={2}>
                        {/*{console.log('User Role:', userRole, 'Type:', typeof userRole, 'Converted:', +userRole, 'Type:', typeof +userRole)}*/}
                        <DatePicker
                            label="Fecha de inicio"
                            value={startDate}
                            readOnly={+userRole !== 0 && +userRole !== 1}
                            onChange={(date) => setStartDate(dayjs(date))}
                            textField={(params) => <TextField {...params} fullWidth />}
                        />
                    </Grid>
                    <Grid item xs={6} sm={2}>
                        <DatePicker
                            label="Fecha de fin"
                            value={endDate}
                            onChange={(date) => setEndDate(dayjs(date))}
                            readOnly={+userRole !== 0 && +userRole !== 1}
                            textField={(params) => <TextField {...params} fullWidth />}
                        />
                    </Grid>
                    <Grid item xs={12} sm={2}>
                                <Typography sx={{fontWeight:'bold',  fontSize: '18px' }}>Saldo en Efectivo</Typography>
                                <LinearProgress
                                    variant="determinate"
                                    value={(progressValue / progressMax) * 100} // Calcula el porcentaje
                                />
                                <Typography sx={{fontWeight:'bold',  fontSize: '15px' }}>{progressValue}</Typography>
                    </Grid>
                    <Grid item xs={12} sm={2}>
                        <Button variant="contained" onClick={handleOpenChartModal}>
                            Ventas
                        </Button>
                    </Grid>


                    {/* Gauge Charts */}
                    <Grid container spacing={1} sx={{ marginLeft: '0rem', marginTop: '0.1rem', marginBottom: '0.1rem' }}>

                        <Grid item xs={12} sm={6} md={3}>
                            <Card elevation={3}>
                                <CardContent>
                                    <ReactECharts option={gaugeOption('Venta Total', gaugeData.total)} />
                                </CardContent>
                            </Card>
                        </Grid>
                        <Grid item xs={12} sm={6} md={3}>
                            <Card elevation={3}>
                                <CardContent>
                                    <ReactECharts option={gaugeOption('Ingresos', gaugeData.ingresos)} />
                                </CardContent>
                            </Card>
                        </Grid>
                        <Grid item xs={12} sm={6} md={3}>
                            <Card elevation={3}>
                                <CardContent>
                                    <ReactECharts option={gaugeOption('Egresos', gaugeData.egresos, true)} />
                                </CardContent>
                            </Card>
                        </Grid>
                        <Grid item xs={12} sm={6} md={3}>
                            <Card elevation={3}>
                                <CardContent>
                                    <ReactECharts option={gaugeOption('Disponible', gaugeData.disponible)} />
                                </CardContent>
                            </Card>
                        </Grid>
                    </Grid>


                    {/* Ingresos y Egresos DataGrids */}
                    <Grid item xs={12} sm={6}>
                        <Card elevation={3}>
                            <CardContent>
                                <Typography>Ingresos</Typography>

                                <Grid container spacing={2} alignItems="center"  sx={{
                                        marginBottom: '0.1rem'}}>
                                    <Grid item xs={8}>
                                        <TextField
                                            variant="outlined"
                                            fullWidth
                                            sx={{
                                                marginBottom: '0 rem',
                                                height: '35px', // Asegura que la altura coincida con la del botón
                                                '& .MuiInputBase-root': {  // Ajusta el campo de entrada
                                                    height: '35px',  // Asegura la misma altura
                                                    padding: '0 14px',  // Ajusta el padding para centrar el texto
                                                    display: 'flex',
                                                    alignItems: 'center',  // Centra verticalmente el placeholder
                                                    fontSize: '16px',  // Ajusta el tamaño de fuente para que coincida
                                                },
                                                '& .MuiOutlinedInput-root': {  // Ajusta el contenedor del input
                                                    height: '35px',  // Asegura la misma altura
                                                },
                                            }}
                                            value={searchIngresosTerm}
                                            onChange={(e) => setSearchIngresosTerm(e.target.value)}
                                        />
                                    </Grid>
                                    <Grid item xs={4}>
                                        <Button
                                            startIcon={<PaidIcon />}
                                            variant="contained"
                                            color="success"
                                            fullWidth
                                            onClick={() => setIngresoModalOpen(true)}
                                        >
                                            Ingreso
                                        </Button>
                                    </Grid>
                                </Grid>

                                <div style={{ height: 400, width: '100%' }}>
                                    <DataGrid rows={ingresosFiltrados} className="header-data-grid" columns={[
                                        { field: 'id', headerName: 'ID', minWidth: 100, flex: 1 },
                                        { field: 'tipo', headerName: 'Tipo', minWidth: 140 , flex: 1},
                                        { field: 'monto', headerName: 'Monto', minWidth: 140 , flex: 1},
                                        { field: 'detalle', headerName: 'Detalle', minWidth: 220 , flex: 1},
                                        { field: 'fecha', headerName: 'Fecha', minWidth: 180 , flex: 1},
                                        {
                                            field: 'delete',
                                            headerName: 'Eliminar',
                                            sortable: false,
                                            minWidth: 100, flex: 1,
                                            disableClickEventBubbling: true,
                                            renderCell: (params) => {
                                                const onClick = async () => {
                                                    setSelectedId(params.id);
                                                    setOpenDialog(true);
                                                };
                                                return <IconButton color="error" onClick={onClick}><DeleteIcon /></IconButton>;
                                            }
                                        }
                                    ]} />
                                </div>
                            </CardContent>
                        </Card>
                    </Grid>

                    <Grid item xs={12} sm={6}>
                        <Card elevation={3}>
                            <CardContent>
                                <Typography>Egresos</Typography>

                                <Grid container spacing={2} alignItems="center" sx={{
                                    marginBottom: '0.1rem'}}>
                                    <Grid item xs={8}>
                                        <TextField
                                            variant="outlined"
                                            sx={{
                                                marginBottom: '0 rem',
                                                height: '35px', // Asegura que la altura coincida con la del botón
                                                '& .MuiInputBase-root': {  // Ajusta el campo de entrada
                                                    height: '35px',  // Asegura la misma altura
                                                    padding: '0 14px',  // Ajusta el padding para centrar el texto
                                                    display: 'flex',
                                                    alignItems: 'center',  // Centra verticalmente el placeholder
                                                    fontSize: '16px',  // Ajusta el tamaño de fuente para que coincida
                                                },
                                                '& .MuiOutlinedInput-root': {  // Ajusta el contenedor del input
                                                    height: '35px',  // Asegura la misma altura
                                                },
                                            }}
                                            fullWidth
                                            value={searchEgresosTerm}
                                            onChange={(e) => setSearchEgresosTerm(e.target.value)}
                                        />
                                    </Grid>
                                    <Grid item xs={4}>
                                        <Button
                                            startIcon={<ProductionQuantityLimitsIcon />}
                                            variant="contained"
                                            color="error"
                                            fullWidth
                                            onClick={() => setEgresoModalOpen(true)}
                                        >
                                            Egreso
                                        </Button>
                                    </Grid>
                                </Grid>
                                <div style={{ height: 400, width: '100%' }}>
                                    <DataGrid rows={egresosFiltrados} className="header-data-grid" columns={[
                                        { field: 'id', headerName: 'ID', minWidth: 70, flex: 1  },
                                        { field: 'tipo', headerName: 'Tipo', minWidth: 130, flex: 1 },
                                        { field: 'monto', headerName: 'Monto', minWidth: 130, flex: 1 },
                                        { field: 'detalle', headerName: 'Detalle', minWidth: 1000, flex: 1 },
                                        { field: 'fecha', headerName: 'Fecha', minWidth: 150, flex: 1 },
                                        { field: 'categoria', headerName: 'Categoría', minWidth: 150, flex: 1 },
                                        {
                                            field: 'delete',
                                            headerName: 'Eliminar',
                                            sortable: false,
                                            minWidth: 100, flex: 1,
                                            disableClickEventBubbling: true,
                                            renderCell: (params) => {
                                                const onClick = () => {
                                                    setSelectedEgresoId(params.id);
                                                    setOpenEgresoDialog(true);
                                                };
                                                return <IconButton color="error" onClick={onClick}><DeleteIcon /></IconButton>;
                                            }
                                        }
                                    ]} />
                                </div>
                            </CardContent>
                        </Card>
                    </Grid>
                </Grid>
            </Container>

            <IngresoModal open={ingresoModalOpen} onClose={() => setIngresoModalOpen(false)} onCreate={handleCreateIngreso} />
            <EgresoModal open={egresoModalOpen} onClose={() => setEgresoModalOpen(false)} onCreate={handleCreateEgreso} />
            <ToastContainer />
        </LocalizationProvider>
    );
}
