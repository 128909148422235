import React, { useEffect, useState } from 'react';
import axios from 'axios';
import config from "../system/config";
import { Button, TextField, Alert, Stack, Container, Paper, Typography } from '@mui/material';

function UpdatePasswordComponent() {
    // Estado para la nueva contraseña y confirmación de contraseña
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [error, setError] = useState('');
    const [success, setSuccess] = useState('');
    const [storedFullname, setStoredFullname] = useState(''); // Estado para almacenar el nombre completo

    // Manejar cambio en los inputs del formulario
    const handlePasswordChange = (event) => {
        setPassword(event.target.value);
    };

    const handleConfirmPasswordChange = (event) => {
        setConfirmPassword(event.target.value);
    };

    // Manejar el envío del formulario
    const handleSubmit = async (event) => {
        event.preventDefault();

        // Obtener datos del usuario desde localStorage
        const userId = localStorage.getItem('userId');
        // Validar si la contraseña no está vacía
        if (!password || !confirmPassword) {
            return setError('Ambos campos de contraseña son requeridos');
        }

        // Verificar si las contraseñas coinciden
        if (password !== confirmPassword) {
            return setError('Las contraseñas no coinciden');
        }

        try {
            // Llamada al API para actualizar la contraseña
            const response = await axios.put(`${config.baseURL}/actualizar-contrasena`, { id: userId, password });

            if (response.data.status) {
                setSuccess('¡Contraseña actualizada con éxito!');
                setError(''); // Limpiar errores previos si la solicitud fue exitosa
            } else {
                setError('Error al actualizar la contraseña.');
            }
        } catch (error) {
            setError('Error al actualizar la contraseña.');
        }
    };

    // Usar useEffect para recuperar el nombre completo del localStorage cuando el componente se monta
    useEffect(() => {
        const fullname = localStorage.getItem('fullname');
        if (fullname) {
            setStoredFullname(fullname);
        }
    }, []); // Las dependencias vacías aseguran que esto se ejecute solo una vez, al montar el componente

    return (
        <Container component="main" maxWidth="xs">
            <Paper elevation={3} sx={{ padding: 3, display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                <Typography component="h1" variant="h5">
                    Cambiar contraseña
                </Typography>
                <Typography component="h6" variant="subtitle1">
                    de: {storedFullname && storedFullname}
                </Typography>
                <Stack spacing={2} width="100%">
                    {error && <Alert severity="error">{error}</Alert>}
                    {success && <Alert severity="success">{success}</Alert>}
                </Stack>
                <form onSubmit={handleSubmit}>
                    <TextField
                        label="Nueva contraseña"
                        type="password"
                        value={password}
                        onChange={handlePasswordChange}
                        variant="outlined"
                        margin="normal"
                        required
                        fullWidth
                        autoComplete="new-password"
                    />
                    <TextField
                        label="Confirmar contraseña"
                        type="password"
                        value={confirmPassword}
                        onChange={handleConfirmPasswordChange}
                        variant="outlined"
                        margin="normal"
                        required
                        fullWidth
                        autoComplete="new-password"
                    />
                    <Button
                        type="submit"
                        variant="contained"
                        color="primary"
                        sx={{ mt: 3, mb: 2 }}
                    >
                        Actualizar contraseña
                    </Button>
                </form>
            </Paper>
        </Container>
    );
}

export default UpdatePasswordComponent;
