import React, { useState, useEffect } from 'react';
import { DataGrid } from '@mui/x-data-grid';
import {Button, Grid, TextField} from '@mui/material';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';
import axios from 'axios';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import DeleteIcon from '@mui/icons-material/Delete';
import config from "../system/config";
import '../HeaderGrid.css';
import 'dayjs/locale/es';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
dayjs.extend(utc); // Usa el plugin utc
dayjs.extend(timezone); // Usa el plugin timezone
dayjs.locale('es'); // Establece el locale de dayjs a español

function VentasTable() {
    const [ventas, setVentas] = useState([]);


    const [openDialog, setOpenDialog] = useState(false);
    const [ventaToDelete, setVentaToDelete] = useState(null);

    const [startDate, setStartDate] = useState(dayjs().tz(`${config.timezonewislla}`));
    const [endDate, setEndDate] = useState(dayjs().tz(`${config.timezonewislla}`));

    useEffect(() => {
        const fetchData = async () => {
            try {

                const formattedStartDate = startDate.tz(`${config.timezonewislla}`).format('YYYY-MM-DD');
                const formattedEndDate = endDate.tz(`${config.timezonewislla}`).format('YYYY-MM-DD');


                const response = await axios.get(`${config.baseURL}/getVentas`, {
                    params: {
                        startDate: formattedStartDate,
                        endDate: formattedEndDate
                    }

                });

             //   console.log('inicio',startDate);
              //  console.log('fin',endDate);

                if (response.data.status) {
                    setVentas(response.data.data);
                }
            } catch (error) {
                console.error("Error fetching data:", error);
            }
        };

        fetchData();
    }, [startDate, endDate]);  // Actualizamos automáticamente cuando cambian startDate o endDate

    const theme = useTheme();
    const isLargeScreen = useMediaQuery(theme.breakpoints.up('md'));
    const handleDelete = async (ventaId) => {
        try {
            const fullname = localStorage.getItem('fullname');
            const response = await axios.post(`${config.baseURL}/eliminarVenta`, { ids: ventaId , userfullname: fullname});
            if (response.data.status) {
                const updatedVentas = ventas.filter(venta => venta.ids !== ventaId); // Filtra el registro eliminado
                setVentas(updatedVentas);  // Actualiza la lista localmente
                setOpenDialog(false);  // Cierra el diálogo
            }
        } catch (error) {
            console.error("Error deleting data:", error);
        }
    };


    const columns = [
        {
            field: 'actions',
            headerName: 'Eliminar',
            minWidth: 130,flex: 1,
            renderCell: (params) => (
                <Button
                    color="secondary"
                    startIcon={<DeleteIcon />}
                    onClick={() => {
                        setVentaToDelete(params.row);
                        setOpenDialog(true);
                    }}
                >
                    Eliminar
                </Button>
            ),
        },
        { field: 'ids', headerName: 'IDs', minWidth: 350,flex: 1,   renderCell: (params) => (
                <div style={{ whiteSpace: 'normal', wordWrap: 'break-word' }}>
                    {params.value}
                </div>
            ),  },
        { field: 'cliente', headerName: 'Cliente', minWidth: 200, flex: 1},
        { field: 'productocantidad', headerName: 'Producto y Cantidad', minWidth:900, flex: 1,  renderCell: (params) => (
                <div style={{ whiteSpace: 'normal', wordWrap: 'break-word' }}>
                    {params.value}
                </div>
            ), },
        { field: 'mesa', headerName: 'Mesa', minWidth: 100, flex: 1 },
        { field: 'sumatotal', headerName: 'Total', minWidth: 110, flex: 1 },
        { field: 'descripcion', headerName: 'Descripción', minWidth: 150 , flex: 1},
        { field: 'usuario', headerName: 'Usuario', minWidth: 250, flex: 1 },
        { field: 'reserva', headerName: 'Reserva', minWidth: 120, flex: 1 },
        { field: 'creado_date', headerName: 'Fecha', minWidth: 120 , flex: 1},
        { field: 'hora', headerName: 'Hora', minWidth: 110 , flex: 1},
        { field: 'observaciones', headerName: 'Observaciones', minWidth: 200 , flex: 1},
        { field: 'direccion', headerName: 'Dirección', minWidth: 200 , flex: 1},
        { field: 'telefono', headerName: 'Teléfono', minWidth: 130 , flex: 1},
    ];


    return (


        <div style={{ maxWidth: isLargeScreen ? '95%' : '100%', margin: '0 auto' }}>

            <LocalizationProvider dateAdapter={AdapterDayjs}>
                <div style={{
                    display: 'flex',
                    alignItems: 'center',
                    flexWrap: 'wrap',
                    marginBottom: '20px',
                    marginTop: '10px',
                }}>

                    <Grid container spacing={3} sx={{marginTop:'1rem'}}>

                        <Grid item xs={6} sm={2}>
                            {/*{console.log('User Role:', userRole, 'Type:', typeof userRole, 'Converted:', +userRole, 'Type:', typeof +userRole)}*/}
                            <DatePicker
                                label="Fecha de inicio"
                                value={startDate}
                                onChange={date => setStartDate(dayjs(date))}
                                textField={(params) => <TextField {...params} style={{ flex: '1', margin: '10px', minWidth: 'calc(50% - 20px)' }} />}
                            />
                        </Grid>
                        <Grid item xs={6} sm={2}>
                            <DatePicker
                                label="Fecha de finalización"
                                value={endDate}
                                onChange={date => setEndDate(dayjs(date))}
                                textField={(params) => <TextField {...params} style={{ flex: '1', margin: '10px', minWidth: 'calc(50% - 20px)' }} />}
                            />
                        </Grid>

                    </Grid>

                </div>
            </LocalizationProvider>


            <div style={{ height: 650, width: '100%', marginTop: '20px' }}>
                <DataGrid
                    className="header-data-grid"
                    rows={ventas}
                    columns={columns}
                    rowsPerPageOptions={[10, 25, 50]}
                    paginationMode="client"
                    checkboxSelection={false}
                    getRowId={(row) => row.ids}
                />
            </div>


            <Dialog
                open={openDialog}  // Cambiar a openDialog
                onClose={() => setOpenDialog(false)}
            >
                <DialogTitle>Eliminar Venta</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        ¿Estás seguro de que quieres eliminar esta venta?
                    </DialogContentText>
                    {ventaToDelete && (
                        <div>
                            <p><strong>IDs:</strong> {ventaToDelete.ids}</p>
                            <p><strong>Cliente:</strong> {ventaToDelete.cliente}</p>
                            <p><strong>Producto y Cantidad:</strong> {ventaToDelete.productocantidad}</p>
                            <p><strong>Mesa:</strong> {ventaToDelete.mesa}</p>
                        </div>
                    )}
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => {
                        setVentaToDelete(null);  // Esto garantiza que reseteamos ventaToDelete cuando se cierra el diálogo.
                        setOpenDialog(false);
                    }} color="primary">
                        Cancelar
                    </Button>
                    <Button onClick={() => {
                        if (ventaToDelete) {
                            handleDelete(ventaToDelete.ids);
                        }
                    }} color="secondary">
                        Eliminar
                    </Button>
                </DialogActions>
            </Dialog>

        </div>
    );
}

export default VentasTable;
