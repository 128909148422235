import config from "../system/config";
import {Navigate} from "react-router-dom";
import MenuComponent from "./Menu";

function PrivateRoute({ children, allowedRoles }) {
    const userRole = localStorage.getItem('userRole');
    const isManager = localStorage.getItem('userManager') === '1';

    const userRoles = isManager
        ? [...(config.rolesAccess[userRole] || []), ...(config.managerAccess[1] || [])]
        : (config.rolesAccess[userRole] || []);


    const isAllowed = userRoles.some(role => allowedRoles.includes(role));

    // Si no está permitido, puedes redirigir o renderizar un componente de "acceso denegado"
    return isAllowed ? children : <Navigate to="/login" />;
}

export default PrivateRoute;
