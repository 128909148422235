import React, { useState, useEffect } from 'react';
import { Button, TextField, Container, Typography } from '@mui/material';
import axios from 'axios';
import {toast, ToastContainer} from "react-toastify";
import config from "../system/config";

export default function StockForm() {
    const [values, setValues] = useState({
        stockcecinashilpida: '',
        stockcuy: '',
        stockmechadito: '',
        stockarrozblanco: '',
        stocktrigo: '',
        stockpapablanca: '',
        stockpapaaderezada: '',
        stockpapaperuana: '',
        stockcgallina: '',
        stockcpata: '',
        stockccabeza: '',
        stockyuca: '',
        stockchoclo: '',
        stockñuña: '',
        stockpatosabores: '',
        stockpolloalaplancha: ''
    });

    const generateKeyFromProductName = (name) => {
        return 'stock' + name.toLowerCase().replace(/[^a-zñ]+/g, '');
    }

    useEffect(() => {
        // Obtener los valores iniciales al montar el componente
        axios.get(`${config.baseURL}/getStockDia`).then(response => {
            console.log('response', response)

            if (response.data.status) {
                const stocks = response.data.data.reduce((acc, curr) => {
                    const key = generateKeyFromProductName(curr.nombre);
                    acc[key] = curr.stock;
                    return acc;
                }, {});

                console.log('stocks', stocks)
                setValues(stocks);
            } else {
                console.error('Error fetching stock data');
            }
        }).catch(error => {
            console.error('Error fetching stock data:', error);
        });
    }, []);

    const handleChange = (event) => {
        const { name, value } = event.target;
        setValues(prevValues => ({
            ...prevValues,
            [name]: value
        }));
        console.log('setValues', values)
    };

    const handleSubmit = () => {
        axios.post(`${config.baseURL}/updateStockDia`, values).then(response => {
            if (response.data.status) {
                toast.success("Stock Actualizado correctamente!");
            } else {
                toast.error("Error actualizando stock!");
            }
        }).catch(error => {
            toast.error("Error actualizando stock!", error);
        });
    };

    return (
        <Container>
            <Typography variant="h4" gutterBottom>Actualizar stock de cada insumo</Typography>

            <TextField
                fullWidth
                margin="normal"
                label="Stock Cecina 1 PORCIÓN"
                name="stockcecinashilpida"
                value={values.stockcecinashilpida}
                onChange={handleChange}
            />

            <TextField
                fullWidth
                margin="normal"
                label="Stock Cuy ENTERO"
                name="stockcuy"
                value={values.stockcuy}
                onChange={handleChange}
            />

            <TextField
                fullWidth
                margin="normal"
                label="Stock Mechadito"
                name="stockmechadito"
                value={values.stockmechadito}
                onChange={handleChange}
            />

            <TextField
                fullWidth
                margin="normal"
                label="Stock Arroz Blanco"
                name="stockarrozblanco"
                value={values.stockarrozblanco}
                onChange={handleChange}
            />

            <TextField
                fullWidth
                margin="normal"
                label="Stock Trigo"
                name="stocktrigo"
                value={values.stocktrigo}
                onChange={handleChange}
            />

            <TextField
                fullWidth
                margin="normal"
                label="Stock Papa Blanca"
                name="stockpapablanca"
                value={values.stockpapablanca}
                onChange={handleChange}
            />

            <TextField
                fullWidth
                margin="normal"
                label="Stock Papa Aderezada"
                name="stockpapaaderezada"
                value={values.stockpapaaderezada}
                onChange={handleChange}
            />

            <TextField
                fullWidth
                margin="normal"
                label="Stock Papa Peruana"
                name="stockpapaperuana"
                value={values.stockpapaperuana}
                onChange={handleChange}
            />


         {/*   <TextField
                fullWidth
                margin="normal"
                label="Stock Caldo de Gallina"
                name="stockcgallina"
                value={values.stockcgallina}
                onChange={handleChange}
            />


            <TextField
                fullWidth
                margin="normal"
                label="Stock Caldo de Pata"
                name="stockcpata"
                value={values.stockcpata}
                onChange={handleChange}
            />

            <TextField
                fullWidth
                margin="normal"
                label="Stock Caldo de Cabeza"
                name="stockccabeza"
                value={values.stockccabeza}
                onChange={handleChange}
            />*/}

            <TextField
                fullWidth
                margin="normal"
                label="Stock Yuca"
                name="stockyuca"
                value={values.stockyuca}
                onChange={handleChange}
            />

            <TextField
                fullWidth
                margin="normal"
                label="Stock Choclo"
                name="stockchoclo"
                value={values.stockchoclo}
                onChange={handleChange}
            />

            <TextField
                fullWidth
                margin="normal"
                label="Stock Ñuña"
                name="stockñuña"
                value={values.stockñuña}
                onChange={handleChange}
            />


            <TextField
                fullWidth
                margin="normal"
                label="Stock Pato 2 Sabores"
                name="stockpatosabores"
                value={values.stockpatosabores}
                onChange={handleChange}
            />


            <TextField
                fullWidth
                margin="normal"
                label="Stock Pollo a la plancha"
                name="stockpolloalaplancha"
                value={values.stockpolloalaplancha}
                onChange={handleChange}
            />

            <Button
                variant="contained"
                color="primary"
                onClick={handleSubmit}
                style={{marginTop: '20px'}}
            >
                Actualizar
            </Button>
            <ToastContainer position="top-right" autoClose={2000} hideProgressBar={false} newestOnTop={false}
                            closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover/>
        </Container>
    );
}
