import React from 'react';
import QRCodeGenerator from './QRCodeGenerator';
import CuponConsumer from './CuponConsumer'; // Asegúrate de importar el componente CuponConsumer
import { Box, Container, Grid } from '@mui/material';

const Cupon = () => {
    return (
        <Container style={{ marginTop: '3rem' }}>
            <Grid container spacing={3} direction="row" alignItems="stretch">
                {/* La propiedad 'lg' establece el número de columnas que el ítem debe ocupar en pantallas grandes, 'md' en pantallas medianas, etc. */}
                <Grid item xs={12} md={6} lg={6}>
                    <Box sx={{ height: '100%', display: 'flex', flexDirection: 'column' }}>
                        <QRCodeGenerator />
                    </Box>
                </Grid>
                <Grid item xs={12} md={6} lg={6}>
                    <Box sx={{ height: '100%', display: 'flex', flexDirection: 'column' }}>
                        <CuponConsumer />
                    </Box>
                </Grid>
            </Grid>
        </Container>
    );
}

export default Cupon;
