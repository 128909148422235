import React, { useState } from 'react';
import { DataGrid } from '@mui/x-data-grid';
import { TextField, Button, Grid } from '@mui/material'; // Importar TextField y Button
import config from "../system/config";
import '../HeaderGrid.css';
import {useTheme} from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
function DireccionesList() {
  const [nombre, setNombre] = useState('');
  const [direcciones, setDirecciones] = useState([]);
  const [loading, setLoading] = useState(false);

  const fetchDirecciones = async (searchName) => {
    setLoading(true);
    const response = await fetch(`${config.baseURL}/GetDireccion/${searchName}`);
    const data = await response.json();

    console.log(data);  // Para ver qué está devolviendo tu API

    if (!data.status || !Array.isArray(data.data)) {
      console.error('The data received is not formatted as expected');
      setLoading(false);
      return;
    }

    const direccionesConId = data.data.map((direccion, index) => ({
      ...direccion,
      id: index,  // Aquí añadimos un ID basado en el índice
    }));

    setDirecciones(direccionesConId);
    setLoading(false);
  };
  const handleKeyUp = (event) => {
    if (event.key === 'Enter' && nombre.length > 2) {
      fetchDirecciones(nombre);
    }
  };

  const columns = [
    { field: 'cliente', headerName: 'Cliente', minWidth: 200 , flex: 1 ,  renderCell: (params) => (
          <div style={{ whiteSpace: 'normal', wordWrap: 'break-word' }}>
            {params.value}
          </div>
      ),  },
    { field: 'telefono', headerName: 'Teléfono',minWidth: 200 , flex: 1 , },
    { field: 'direccion', headerName: 'Dirección', minWidth: 600 , flex: 1 ,   renderCell: (params) => (
          <div style={{ whiteSpace: 'normal', wordWrap: 'break-word' }}>
            {params.value}
          </div>
      ), },
    { field: 'creado', headerName: 'Creado',minWidth: 200 , flex: 1 , },
    { field: 'observaciones', headerName: 'Observaciones', minWidth: 300 , flex: 1 ,},
    { field: 'hora', headerName: 'Hora', minWidth: 100 , flex: 1 ,},
    { field: 'reserva', headerName: 'Reserva', minWidth: 200 , flex: 1 , },
    { field: 'usuario', headerName: 'Usuario' , minWidth: 300 , flex: 1 ,},
    { field: 'venta_id', headerName: 'Venta ID', minWidth: 200 , flex: 1 , },
  ];

  const theme = useTheme();
  const isLargeScreen = useMediaQuery(theme.breakpoints.up('md'));

  return (

  <div style={{ maxWidth: isLargeScreen ? '98%' : '100%', margin: '0 auto'}}>


    <div style={{width: '100%', marginTop: '20px' }}>

    <Grid container spacing={2} alignItems="center">

      <Grid item xs={6} sm={2}>
        {/*{console.log('User Role:', userRole, 'Type:', typeof userRole, 'Converted:', +userRole, 'Type:', typeof +userRole)}*/}
        <TextField
            id="nombre"
            label="Nombre"
            value={nombre}
            onKeyUp={handleKeyUp}
            onChange={(e) => setNombre(e.target.value)}
            placeholder="Introduce el nombre"
            variant="outlined" // Para darle un estilo con borde
        />
      </Grid>
      <Grid item xs={6} sm={2}>
        <Button
            id="buscardirecciones"
            onClick={() => fetchDirecciones(nombre)}
            disabled={nombre.length <= 2}
            color="secondary" // Color primario (usualmente es azul)
            variant="contained" // Botón con fondo de color
        >
          Buscar Direcciones
        </Button>
      </Grid>

        </Grid>
    </div>


      <div style={{ height: 650, width: '100%', marginTop: '20px' }}>

        {loading ? (
            <p>Loading...</p>
        ) : (
              <DataGrid
                  className="header-data-grid"
                  rows={direcciones}
                  columns={columns}
                  pageSize={5}
                  rowsPerPageOptions={[10, 25, 50]}
              />
        )}

      </div>
  </div>

  );

}

export default DireccionesList;
