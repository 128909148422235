import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Box, LinearProgress, Typography } from '@mui/material';
import config from "../system/config";

const StockProgressBar = () => {
    const [products, setProducts] = useState([]);
    const [loading, setLoading] = useState(true);

    // 1. Función para manejar la solicitud y establecer los datos
    const fetchData = () => {
        axios.get(`${config.baseURL}/consumodia`)
            .then(response => {
                if (response.data.status) {
                    setProducts(response.data.data);
                } else {
                    console.error('Error fetching data:', response.data);
                }
                setLoading(false);
            })
            .catch(error => {
                console.error('There was an error fetching the data:', error);
                setLoading(false);
            });
    };

    useEffect(() => {
        fetchData(); // Llamar a la función inicialmente

        // 2. Establecer un intervalo que llame a la función cada segundo
        const intervalId = setInterval(fetchData, 1000);

        // 3. Limpiar el intervalo cuando el componente se desmonte
        return () => clearInterval(intervalId);
    }, []);

    return (
        <Box display="flex" flexDirection="row" justifyContent="space-between">
            {products.map((product, index) => {
                const progressPercentage = (product.restante / product.stock) * 100;

                // Determinar el color de la barra basado en el porcentaje
                let barColor;
                if (progressPercentage < 30) {
                    barColor = '#b25757'; // Rojo
                } else if (progressPercentage < 60) {
                    barColor = '#ffa500'; // Naranja
                } else {
                    barColor = '#3fb576'; // Azul predeterminado de MUI
                }

                return (
                    <Box
                        key={product.nombre}
                        sx={{
                            mb: 2,
                            width: `calc(100% / 15 - 0.5rem)`,
                            mr: index === products.length - 1 ? '0' : '0.8rem'
                        }}
                    >
                        <Typography variant="h6" textAlign="center" style={{ fontSize: '0.6rem' }}>
                            {product.nombre}
                        </Typography>
                        <LinearProgress
                            variant="determinate"
                            value={progressPercentage}
                            sx={{
                                height: '10px',
                                '& .MuiLinearProgress-barColorPrimary': {
                                    backgroundColor: barColor
                                },
                                backgroundColor: (theme) => theme.palette.grey[400]
                            }}
                        />
                        <Typography variant="body2" textAlign="center" style={{ fontSize: '0.6rem' }}>
                            {product.restante} de {product.stock}
                        </Typography>
                    </Box>
                );
            })}

        </Box>
    );
};

export default StockProgressBar;
