import React from 'react';
import { Chip } from '@mui/material';

const chipData = [
    { label: "Mesa", color: "#ffa78f" },
    { label: "Delivery", color: "#cfa5d9" },
    { label: "Recoger", color: "#aee79f" },
    { label: "Reserva", color: "#b0b0b0" },
    { label: "Listo para servir", color: "#64d2e5" },
    { label: "Atendido", color: "#ead080" },
    { label: "Libre", color: "#fff" }
    //... puedes agregar más si lo necesitas
];

const ChipSet = () => {
    return (
        <>
            {chipData.map((chip, index) => (
                <Chip
                    key={index}
                    label={chip.label}
                    sx={{
                        height: '20px',
                        color: 'black',
                        fontSize: '10px',
                        fontWeight:'bold',
                        backgroundColor: chip.color,
                        border: '1px solid #000',
                        marginRight: '5px'
                    }}
                />
            ))}
        </>
    );
}

export default ChipSet;
