import React, { createContext, useState, useMemo } from 'react';
import axios from 'axios';
import config from "../system/config";

const AppContext = createContext();

export default AppContext;

export const AppProvider = ({ children }) => {
    const [isLoggedIn, setIsLoggedIn] = useState(false);
    const [darkMode, setDarkMode] = useState(true);
    const [username, setUsername] = useState('');
    const [fullname, setFullname] = useState('');
    const [userId, setUserId] = useState(null);
    const [userRole, setUserRole] = useState('');
    const [userManager, setUserManager] = useState('');

    // Función para almacenar datos en localStorage.
    const saveToLocalStorage = (key, value) => {
        localStorage.setItem(key, value);
    }

    const handleLogin = async (status, email) => {
        setIsLoggedIn(status);

        if (status) {
            try {
                const response = await axios.get(`${config.baseURL}/getUserName?emailAddress=${email}`);
               // console.log('response.data AppContext', response.data);

                setUsername(response.data.fullName);
                setFullname(response.data.fullName);
                setUserId(response.data.id);
                setUserRole(response.data.rol);
                setUserManager(response.data.manager);

                // Guardar los datos en el localStorage.
                saveToLocalStorage('username', response.data.fullName);
                saveToLocalStorage('fullname', response.data.fullName);
                saveToLocalStorage('userId', response.data.id);
                saveToLocalStorage('userRole', response.data.rol);
                saveToLocalStorage('userManager', response.data.manager);

                // Imprimir en console.log.
                // console.log('Datos almacenados en localStorage:', {
                //     username: response.data.fullName,
                //     fullname: response.data.fullName,
                //     userId: response.data.id,
                //     userRole: response.data.rol,
                //     userManager: response.data.manager
                // });
            } catch (error) {
                console.error('Error al obtener el nombre de usuario', error);
            }
        } else {
            setUsername(null);
            setFullname(null);
            setUserId(null);
            setUserRole(null);
            setUserManager(null);

            // Limpiar los datos del localStorage si el usuario cierra sesión.
            localStorage.removeItem('username');
            localStorage.removeItem('fullname');
            localStorage.removeItem('userId');
            localStorage.removeItem('userRole');
            localStorage.removeItem('userManager');
        }
    };

    const contextValue = useMemo(() => ({
        isLoggedIn, setIsLoggedIn,
        darkMode, setDarkMode,
        username, setUsername,
        fullname, setFullname,
        userId, setUserId,
        userRole, setUserRole,
        userManager, setUserManager,
        handleLogin
    }), [isLoggedIn, darkMode, username, fullname, userId, userRole, userManager]);

    return (
        <AppContext.Provider value={contextValue}>
            {children}
        </AppContext.Provider>
    );
};
