import React, { useState, useEffect } from 'react';
import axios from 'axios';
import ReactECharts from 'echarts-for-react';
import config from "../system/config";

function TopClientes() {
    const [clientesData, setClientesData] = useState([]);

    useEffect(() => {
        fetchTopClientes();
    }, []);

    const fetchTopClientes = async () => {
        try {
            const response = await axios.get(`${config.baseURL}/topclientes`);
            if (response.data.status) {
                const top = response.data.data.slice(0, 100);
                setClientesData(top);
            }
        } catch (error) {
            console.error("Error fetching top clientes data:", error);
        }
    };

    const getOptions = () => {
        const clientesNames = clientesData.map(cliente => cliente.cliente);
        const clientesAmounts = clientesData.map(cliente => cliente.cantidad);
        const clientesTotalVenta = clientesData.map(cliente => cliente.totalventa);
        const clientesLastOrder = clientesData.map(cliente => cliente.ultimopedido);

        return {
           // title: {
           //     text: 'Top Clientes Frecuentes'
           // },
            tooltip: {
                trigger: 'axis',
                formatter: function(params) {
                    let tooltipData = `${params[0].name}<br>`;
                    for (let i = 0; i < params.length; i++) {
                        tooltipData += `${params[i].marker} ${params[i].seriesName}: ${params[i].value}<br>`;
                    }
                    tooltipData += `Último pedido: ${clientesLastOrder[params[0].dataIndex]}`;
                    return tooltipData;
                }
            },
            toolbox: {
                feature: {
                    saveAsImage: {
                        title: 'Descargar Gráfico'
                    }
                }
            },
            legend: {
                data: ['Pedidos', 'Total Venta']
            },
            grid: {
                bottom: 180 // Esto da más espacio en la parte inferior del gráfico
            },
            dataZoom: [
                {
                    type: 'inside',
                    start: 0,
                    end: 20  // Esto enfoca en la primera mitad de los datos
                },
                {
                    type: 'slider',
                    start: 0,
                    end: 20,
                    bottom: 10  // Esto mueve el dataZoom un poco más abajo, ajusta este valor según lo necesites
                }
            ],
            xAxis: {
                data: clientesNames,
                axisLabel: {
                    interval: 0,
                    rotate: 30
                }
            },
            yAxis: [
                {
                    type: 'value',
                    name: 'Pedidos',
                    position: 'left'
                },
                {
                    type: 'value',
                    name: 'Total Venta',
                    position: 'right'
                }
            ],
            series: [
                {
                    name: 'Pedidos',
                    type: 'line',
                    data: clientesAmounts,
                    label: {
                        show: true,
                        position: 'top'
                    }
                },
                {
                    name: 'Total Venta',
                    type: 'bar',
                    yAxisIndex: 1,
                    data: clientesTotalVenta,
                    label: {
                        show: true,
                        position: 'insideBottom'
                    }
                }
            ]
        };
    }


    return (
        <div style={{ width: '96%', margin: '0 auto', marginTop: '50px' }}>
            <ReactECharts option={getOptions()} style={{ height: '600px' }} />
        </div>
    );
}

export default TopClientes;
