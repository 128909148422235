import React, { useState } from 'react';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';
import VentasPorFecha from './VentasPorFecha';
import TopClientes from './TopClientes';
import VentaProductoCantidadDiaChart from './VentaProductoCantidadDiaChart';
import Paper from '@mui/material/Paper';
import { Box } from '@mui/system';
import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';

const Reportes = () => {
    const [open, setOpen] = useState(false);
    const [selectedReport, setSelectedReport] = useState('');

    const handleClickOpen = (reportName) => {
        setSelectedReport(reportName);
        setOpen(true);
    };

    const handleClose = () => {
        setSelectedReport('');
        setOpen(false);
    };

    return (
        <Box display="flex" justifyContent="center" mt={3}>
            <Paper style={{ width: '80%', padding: '16px' }}>
                <List component="nav" aria-label="reportes">
                    <ListItem button onClick={() => handleClickOpen('Ventas')}>
                        <ListItemText primary="Ventas" />
                    </ListItem>
                    <ListItem button onClick={() => handleClickOpen('Top Clientes')}>
                        <ListItemText primary="Top Clientes" />
                    </ListItem>
                    <ListItem button onClick={() => handleClickOpen('Venta día')}>
                        <ListItemText primary="Venta día" />
                    </ListItem>
                </List>
            </Paper>


            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="report-dialog-title"
                fullWidth={true}
                maxWidth="xl"
            >
                <DialogTitle id="report-dialog-title">{selectedReport}</DialogTitle>
                <DialogContent style={{ height: '80vh' }}>
                    <IconButton
                        aria-label="close"
                        onClick={handleClose}
                        sx={{
                            position: 'absolute',
                            right: 8,
                            top: 8,
                            width: 48,
                            height: 48,
                        }}
                    >
                        <CloseIcon sx={{ fontSize: '1.0rem' }} />
                    </IconButton>
                    {selectedReport === 'Ventas' && <VentasPorFecha />}
                    {selectedReport === 'Top Clientes' && <TopClientes />}
                    {selectedReport === 'Venta día' && <VentaProductoCantidadDiaChart />}
                    {/* Aquí puedes agregar más componentes de reportes según el reporte seleccionado */}
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} color="primary">
                        Cerrar
                    </Button>
                </DialogActions>
            </Dialog>


        </Box>
    );
};

export default Reportes;
