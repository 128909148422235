import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Typography } from '@mui/material';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper } from '@mui/material';

export const VerificarVentaModal = ({ open, onClose, detallesVenta, onSave, isSaving, isSaved }) => {
    const totalPrecio = detallesVenta.detallesPedido ?
        detallesVenta.detallesPedido.reduce((acc, item) => acc + parseFloat(item.totalindividual), 0) :
        0;

    return (
        <Dialog open={open} onClose={onClose}>
            <DialogTitle style={{ textAlign: 'center' }}>Verificar Venta</DialogTitle>
            <DialogContent>
                <Typography>Cliente: {detallesVenta.cliente}</Typography>
                <Typography>Reserva: {detallesVenta.reserva}</Typography>
                <Typography>Mesa: {detallesVenta.mesa}</Typography>
                <Typography>Detalles del Pedido:</Typography>
                <TableContainer component={Paper} style={{ maxHeight: 400, overflow: 'auto' }}>
                    <Table size="small" aria-label="a dense table">
                        <TableHead>
                            <TableRow>
                                <TableCell>Plato</TableCell>
                                <TableCell align="right">Cantidad</TableCell>
                                <TableCell align="right">Tipo</TableCell>
                                <TableCell align="right">Total</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {detallesVenta.detallesPedido && detallesVenta.detallesPedido.map((item, index) => (
                                <TableRow key={index}>
                                    <TableCell component="th" scope="row">
                                        {item.nombrePlato}
                                    </TableCell>
                                    <TableCell align="right">{item.cantidad}</TableCell>
                                    <TableCell align="right">{item.tipo}</TableCell>
                                    <TableCell align="right">{item.totalindividual}</TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
                <Typography variant="h6" style={{ marginTop: 15 }}>Total Precio: S/.{totalPrecio.toFixed(2)}</Typography>
                <Typography>Observaciones: {detallesVenta.observaciones}</Typography>
                <Typography>Teléfono: {detallesVenta.telefono}</Typography>
                <Typography>Dirección: {detallesVenta.direccion}</Typography>
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose} color="secondary">Regresar</Button>
                {!isSaved && (
                    <Button onClick={onSave} color="primary" disabled={isSaving}>
                        {isSaving ? 'Guardando...' : 'Guardar'}
                    </Button>
                )}
            </DialogActions>
        </Dialog>
    );
};
