import React, { useState, useEffect } from 'react';
import ReactECharts from 'echarts-for-react';
import axios from "axios";
import config from "../system/config";

function VentasPorFecha() {
    const [ventas, setVentas] = useState([]);

    useEffect(() => {
        axios.get(`${config.baseURL}/ventaGeneral`)
            .then(response => {
                if (response.data.status) {
                    setVentas(response.data.data);
                }
            })
            .catch(error => {
                console.error("Error fetching the sales data:", error);
            });
    }, []);

    const options = {
       // title: {
         //   text: 'Venta General'
       // },
        tooltip: {
            trigger: 'axis'
        },
        xAxis: {
            data: ventas.map(item => item.fecha)
        },
        yAxis: {
            splitLine: {
                show: false
            }
        },
        toolbox: {
            left: 'center',
            feature: {
                saveAsImage: { title: 'Descargar Reporte' }
            }
        },
        grid: {
            bottom: 100 // Esto da más espacio en la parte inferior del gráfico
        },
        dataZoom: [
            {
                type: 'inside',
                start: 0,
                end: 100  // Esto muestra todos los datos
            },
            {
                type: 'slider',
                start: 0,
                end: 100,
                bottom: 10  // Puedes ajustar la posición del control deslizante si es necesario
            }
        ],
        visualMap: {
            top: 10,
            right: 10,
            pieces: [{
                gt: 0,
                lte: 100,
                color: '#ff393b'
            }, {
                gt: 100,
                lte: 150,
                color: '#ffa78f'
            }, {
                gt: 150,
                lte: 250,
                color: '#ffad57'
            }, {
                gt: 250,
                lte: 350,
                color: '#accc57'
            }, {
                gt: 350,
                lte: 500,
                color: '#92cc16'
            }, {
                gt: 500,
                color: '#29cc13'
            }],
            outOfRange: {
                color: '#999'
            }
        },
        // backgroundColor: '#fff',
        series: {
            name: 'Venta Total',
            type: 'bar',
            data: ventas.map(item => item.total),
            markLine: {
                silent: true,
                data: [{
                    yAxis: 50
                }, {
                    yAxis: 100
                }, {
                    yAxis: 150
                }, {
                    yAxis: 200
                }, {
                    yAxis: 300
                }, {
                    type: 'average', name: 'PROMEDIO',
                    lineStyle: {
                        color: '#212529'
                    }
                }]
            }
        }
    };

    return (
        <ReactECharts style={{ height: '100%' }} option={options} autoResize />

    );
}

export default VentasPorFecha;
