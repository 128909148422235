import React, {useEffect, useState} from 'react';
import axios from 'axios'; // Asegúrate de instalar axios si aún no lo tienes: `npm install axios`
import QRScanner from './QRScanner'; // Importa tu componente QRScanner
import {Alert, Container, Modal, Snackbar, Typography} from '@mui/material';
import config from "../system/config";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import DialogTitle from "@mui/material/DialogTitle";
import CircularProgress from '@mui/material/CircularProgress';
const CuponConsumer = () => {
    const [open, setOpen] = useState(false);
    const [message, setMessage] = useState('');
    const [severity, setSeverity] = useState('info');

    const [cuponInfo, setCuponInfo] = useState(null);
    const [dialogOpen, setDialogOpen] = useState(false);

    const handleScan = async (data) => {
      //  console.log('Data received from QRScanner:', data);
        setCuponInfo(null); // Limpiar la información anterior del cupón
        try {
            // Hacer la llamada a la API para obtener la información del cupón
            const response = await axios.get(`${config.baseURL}/informacioncupon`, {
                params: { cupon: data } // Asumiendo que 'data' contiene el código del cupón
            });

            if (response.data.status) {
             //   console.log('Cupon information:', response.data.data);
                // Guardamos los datos en el estado y mostramos el diálogo
                setCuponInfo(response.data.data); // Asumiendo que la información del cupón está en 'data'
                setDialogOpen(true);
            } else {
                // Manejar el caso de que no se encuentre el cupón
                setMessage('Cupón no encontrado');
                setSeverity('error');
                setOpen(true);
            }
        } catch (error) {
            //console.error('Error fetching cupon information:', error);
            setMessage('Cupón no encontrado');
            setSeverity('error');
            setOpen(true);
        }
    };

    const confirmConsume = async () => {
        try {
            // Extraer el DNI del primer elemento de cuponInfo
            const dni = cuponInfo[0].cupon; // Asume que "usuario" es el campo que contiene el DNI, ajusta según sea necesario

            // Enviar el DNI en la solicitud POST
            const response = await axios.post(`${config.baseURL}/consumoapi`, { dni });

            //console.log('HTTP Response:', response);
            setMessage(response.data.message);
            setSeverity('success');
        } catch (error) {
            console.error('HTTP Request Error:', error);
            setMessage(error.response?.data?.message || 'Cupón Inválido');
            setSeverity('error');
        } finally {
            setDialogOpen(false); // Cerramos el modal después de enviar los datos
            setOpen(true);
        }
    };


    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpen(false);
    };
    return (
        <Container>
            <QRScanner onScan={handleScan} />
            {cuponInfo ? (
                <Typography component="div">
                    <div style={{ margin: '20px 0', padding: '20px', border: '1px solid #ddd' }}>
                        <h2 style={{ color: '#e15f5f' }}>Información del Cupón:</h2>
                        <p><strong>ID del Cupón:</strong> {cuponInfo[0].idcupon}</p>
                        <p><strong>Cupón:</strong> {cuponInfo[0].cupon}</p>
                        <p><strong>Fecha de Creación:</strong> {new Date(cuponInfo[0].creacion).toLocaleDateString()}</p>
                        <p><strong>Usuario:</strong> {cuponInfo[0].usuario}</p>
                        <p><strong>Cliente:</strong> {cuponInfo[0].cliente}</p>
                        <p><strong>Producto:</strong> {cuponInfo[0].producto}</p>
                        <p><strong>Descripción:</strong> {cuponInfo[0].descripcion}</p>
                        <p><strong>Fecha de Vencimiento:</strong> {new Date(cuponInfo[0].vencimiento).toLocaleDateString()}</p>
                        <p><strong>Estado:</strong> {cuponInfo[0].fueconsumido ? 'Consumido' : 'No Consumido'}</p>
                    </div>
                </Typography>

            ) : (
                // Opcional: mostrar un indicador de carga mientras se recupera la información del cupón
                dialogOpen && <CircularProgress />
            )}
            <Dialog
                open={dialogOpen}
                onClose={() => setDialogOpen(false)}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">{"Confirmar consumo de cupón"}</DialogTitle>
                <DialogContent>
                    <Typography id="alert-dialog-description">
                        ¿Deseas utilizar este cupón?
                    </Typography>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setDialogOpen(false)}>Cancelar</Button>
                    <Button onClick={confirmConsume} autoFocus>
                        Aceptar
                    </Button>
                </DialogActions>
            </Dialog>
            <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
                <Alert onClose={handleClose} severity={severity} sx={{ width: '100%' }}>
                    {message}
                </Alert>
            </Snackbar>
        </Container>
    );
};

export default CuponConsumer;
