import  { useEffect, useState } from 'react';
import axios from 'axios';
import audios from './audioMesas';
import config from "../system/config";  // Importa config

const StockAlert = ({ audioEnabled }) => {
    const [products, setProducts] = useState([]);
    const roltablero = localStorage.getItem('userRole');

    const fetchStockData = async () => {
        try {
            const response = await axios.get(`${config.baseURL}/consumodia`);
            setProducts(response.data.data);
        } catch (error) {
            console.error('Hubo un problema al obtener los datos de stock:', error);
        }
    };

    useEffect(() => {
        const initialDelay = config.initialDelay ; // Retraso inicial de 10 segundos

        const fetchAndLog = async () => {
            await fetchStockData();
        };

        // Establece un retraso inicial antes de comenzar a buscar datos
        const timeoutId = setTimeout(() => {
            fetchAndLog();
            // Después del retraso inicial, comienza a obtener datos a intervalos regulares
            const intervalId = setInterval(fetchAndLog, config.stockCheckInterval);

            return () => clearInterval(intervalId);  // Limpia el intervalo si el componente se desmonta
        }, initialDelay);

        return () => clearTimeout(timeoutId); // Limpia el timeout si el componente se desmonta antes de que se ejecute
    }, []);

    useEffect(() => {
        if (roltablero !== '3' || !audioEnabled) return;

        const outOfStockProducts = products.filter(product => product.restante <= 0);

        if (outOfStockProducts.length > 0) {
            const sonido = new Audio(audios[audios.length - 1]);
            sonido.play();
        }
    }, [products, roltablero, audioEnabled]);

    return null;
};

export default StockAlert;
