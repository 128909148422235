import React, { useState } from 'react';
import { Modal, Box, Button, IconButton, TextField, Typography, MenuItem, InputLabel, FormControl, Select } from '@mui/material';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import config from "../system/config";
import 'dayjs/locale/es';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import CloseIcon from '@mui/icons-material/Close';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { green } from '@mui/material/colors';
dayjs.extend(utc); // Usa el plugin utc
dayjs.extend(timezone); // Usa el plugin timezone
dayjs.locale('es'); // Establece el locale de dayjs a español

function IngresoModal({ open, onClose, onCreate }) {
    const [monto, setMonto] = useState('');
    const [tipo, setTipo] = useState('');
    const [observaciones, setObservaciones] = useState('');
    const [fecha, setFecha] = useState(dayjs().tz(`${config.timezonewislla}`));
    const [isCreateButtonDisabled, setIsCreateButtonDisabled] = useState(false);
    const [buttonText, setButtonText] = useState('Crear');
    const [isSaved, setIsSaved] = useState(false);
    const userRole = Number(localStorage.getItem('userRole'));

    const handleCreate = async () => {
        if (!monto || !tipo || !observaciones || !fecha) {
            toast.error('Todos los campos son obligatorios!');
            return;
        }

        const fechaComoTexto = fecha.format('YYYY-MM-DD');

        const newIngreso = {
            monto,
            tipo,
            observaciones,
            fecha: fechaComoTexto,
            userid: localStorage.getItem('userId')
        };
        try {
            await onCreate(newIngreso);
            toast.success('Ingreso creado con éxito!');
            setIsCreateButtonDisabled(true); // Deshabilitar el botón después de crear el ingreso
            setButtonText('Creado con éxito'); // Cambiar el texto del botón
            setIsSaved(true); // Marcar los inputs como guardados
        } catch (error) {
            toast.error('Error al crear ingreso. Por favor, intenta de nuevo.');
        }
    };

    const handleClose = () => {
        setMonto('');
        setTipo('');
        setObservaciones('');
        setFecha(dayjs().tz(`${config.timezonewislla}`));
        setIsCreateButtonDisabled(false); // Habilitar el botón cuando el modal se cierra
        setButtonText('Crear'); // Restaurar el texto del botón
        setIsSaved(false); // Restablecer el estado de guardado de los inputs
        onClose();
    };

    return (
        <Modal open={open} onClose={handleClose}>
            <Box sx={{
                width: 400,
                padding: 2,
                position: 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
                bgcolor: 'background.paper',
                outline: 'none',
                overflowY: 'scroll',
                maxHeight: '100%',
            }}>
                <IconButton
                    aria-label="close"
                    onClick={handleClose}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        width: 48,
                        height: 48,
                    }}
                >
                    <CloseIcon sx={{ fontSize: '1.0rem' }} />
                </IconButton>
                <Typography variant="h6" sx={{ marginBottom: '1rem' }}>Crear Ingreso</Typography>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DatePicker
                        sx={{ m: 0, width: '100%', marginLeft: '0rem' }}
                        label="Fecha de inicio"
                        readOnly={+userRole !== 0 && +userRole !== 1}
                        value={dayjs(fecha)}
                        onChange={(date) => setFecha(dayjs(date))}
                    />
                </LocalizationProvider>

                <TextField
                    fullWidth
                    margin="normal"
                    label="Monto"
                    type="text"
                    required
                    value={monto}
                    onChange={(e) => {
                        const value = e.target.value.replace(/,/g, '.'); // Convierte comas en puntos para la consistencia
                        if (/^(\d+\.?\d*|\.\d+)$/.test(value) || value === '') {
                            setMonto(value);
                        }
                    }}
                    InputProps={{
                        endAdornment: isSaved && <CheckCircleIcon sx={{ color: green[500] }} />
                    }}
                />

                <FormControl fullWidth margin="normal" required>
                    <InputLabel>Tipo</InputLabel>
                    <Select
                        value={tipo}
                        onChange={(e) => setTipo(e.target.value)}
                        endAdornment={isSaved}
                    >
                        <MenuItem value="Saldo inicial">Saldo inicial</MenuItem>
                        <MenuItem value="Efectivo Billetes">Efectivo Billetes</MenuItem>
                        <MenuItem value="Efectivo Sencillo">Efectivo Sencillo</MenuItem>
                        <MenuItem value="POS">POS</MenuItem>
                        <MenuItem value="Yape">Yape</MenuItem>
                        <MenuItem value="Plin">Plin</MenuItem>
                        <MenuItem value="Transferencia Bancaria BCP (Banco de crédito del Perú)">Transferencia Bancaria BCP (Banco de crédito del Perú)</MenuItem>
                        <MenuItem value="Transferencia Bancaria Interbank">Transferencia Bancaria Interbank</MenuItem>
                        <MenuItem value="Transferencia Bancaria Scotiabank">Transferencia Bancaria Scotiabank</MenuItem>
                        <MenuItem value="Transferencia Bancaria BBVA">Transferencia Bancaria BBVA</MenuItem>
                        <MenuItem value="Transferencia Bancaria Banco de la nación">Transferencia Bancaria Banco de la nación</MenuItem>
                    </Select>
                </FormControl>
                <TextField
                    fullWidth
                    margin="normal"
                    label="Observaciones"
                    multiline
                    rows={8}
                    required
                    value={observaciones}
                    onChange={(e) => setObservaciones(e.target.value)}
                    InputProps={{
                        endAdornment: isSaved && <CheckCircleIcon sx={{ color: green[500] }} />
                    }}
                />
                <Button
                    fullWidth
                    variant="contained"
                    color="primary"
                    onClick={handleCreate}
                    disabled={isCreateButtonDisabled} // Deshabilitar el botón si ya se creó un ingreso
                >
                    {buttonText} {/* Cambiar el texto del botón */}
                </Button>
            </Box>
        </Modal>
    );
}

export default IngresoModal;
