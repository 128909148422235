import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Box, LinearProgress, Typography } from '@mui/material';
import config from "../system/config";

const StockProgressBarVertical = () => {
    const [products, setProducts] = useState([]);
    const [loading, setLoading] = useState(true);

    const fetchData = () => {
        axios.get(`${config.baseURL}/consumodia`)
            .then(response => {
                if (response.data.status) {
                    setProducts(response.data.data);
                } else {
                    console.error('Error fetching data:', response.data);
                }
                setLoading(false);
            })
            .catch(error => {
                console.error('There was an error fetching the data:', error);
                setLoading(false);
            });
    };

    useEffect(() => {
        fetchData();

        const intervalId = setInterval(fetchData, 1000);
        return () => clearInterval(intervalId);
    }, []);

    return (
        <Box display="flex" flexDirection="column" alignItems="center">
            {products.map((product, index) => {
                const progressPercentage = (product.restante / product.stock) * 100;

                let barColor;
                if (progressPercentage < 30) {
                    barColor = '#b25757';
                } else if (progressPercentage < 60) {
                    barColor = '#ffa500';
                } else {
                    barColor = '#3fb576';
                }

                return (
                    <Box
                        key={product.nombre}
                        sx={{
                            mb: 2,
                            width: '100%',
                        }}
                    >
                        <Typography variant="h6" textAlign="center" style={{ fontSize: '0.7rem' }}>
                            {product.nombre}
                        </Typography>
                        <LinearProgress
                            variant="determinate"
                            value={progressPercentage}
                            sx={{
                                height: '10px',
                                '& .MuiLinearProgress-barColorPrimary': {
                                    backgroundColor: barColor
                                },
                                backgroundColor: (theme) => theme.palette.grey[400]
                            }}
                        />
                        <Typography variant="body2" textAlign="center" style={{ fontSize: '0.7rem' }}>
                            Restante: {product.restante} de {product.stock}
                        </Typography>
                    </Box>
                );
            })}
        </Box>
    );
};

export default StockProgressBarVertical;
