import React from 'react';
import {
    Card, CardHeader, CardContent, Grid, useTheme, IconButton, TextField,
    Paper, Typography
} from '@mui/material';
import {PriceCheck, VolumeOff, Visibility, ThumbUpAlt, Info, PriceChange} from '@mui/icons-material';
import axios from 'axios';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import InfoPopover from './PopoverInfo';
import DetailPopover from './PopoverDetail';
import config from "../system/config";
import useMediaQuery from '@mui/material/useMediaQuery';


const Mesa = React.memo(({ mesaNum, darkMode, data, datadetail, userRole }) => {

    //mensajes de confirmacion
    const [dialogOpen, setDialogOpen] = React.useState(false);
    const [confirmAction, setConfirmAction] = React.useState(null);
    const [dialogMessage, setDialogMessage] = React.useState("");
    //vuelto
    const [showChangeModal, setShowChangeModal] = React.useState(false);
    const [amountPaid, setAmountPaid] = React.useState("");
    const [changeAmount, setChangeAmount] = React.useState(0);
    // console.log('darkMode', darkMode);
    const theme = useTheme();
    const isMediumScreenOrSmaller = useMediaQuery(theme.breakpoints.down('sm')); // Prueba con 'md' en lugar de 'sm'
   // console.log("Is medium or smaller screen:", isMediumScreenOrSmaller);

    const responsiveStyles = {
        iconSize: isMediumScreenOrSmaller ? 'small' : 'small', // Cambia 'small' a 'medium' cuando no es una pantalla pequeña
        typographyVariant: isMediumScreenOrSmaller ? 'body2' : 'h6', // Ajusta esto según tus necesidades
    };

    //console.log('datamesa', data);
    //console.log('datadetail', datadetail);


    let cardColor = theme.palette.background.default; // Color por defecto
    //colores segun tipo de pedido y estado

   // console.log(data.estadovalidado);
    if (data.estadovalidado === "4") {
        cardColor = "#64d2e5";
    } else if (data.estadovalidado === "2") {
        cardColor = "#ead080";
    } else if (data.reserva?.includes("MESA")) {
        cardColor = "#ffa78f";
    } else if (data.reserva?.includes("RESERVA")) {
        cardColor = "#b0b0b0";
    } else if (data.reserva?.includes("DELIVERY")) {
        cardColor = "#cfa5d9";
    } else if (data.reserva?.includes("RECOGER")) {
        cardColor = "#aee79f";
    }
    // Para el Popover
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [anchorElDetail, setAnchorElDetail] = React.useState(null);

    const openPopover = (event) => setAnchorEl(event.currentTarget);
    const openPopoverDetail = (event) => setAnchorElDetail(event.currentTarget);
    const closePopover = () => setAnchorEl(null);
    const closePopoverDetail = () => setAnchorElDetail(null);

    const isOpen = Boolean(anchorEl);
    const isOpenDetail = Boolean(anchorElDetail);

    //Roles
    const getButtonAccessByRole = (role) => {
        switch (role) {
            case '0':
            case '1':
                return {
                    PriceCheck: true,
                    VolumeOff: true,
                    Visibility: true,
                    Info: true,
                    PriceChange: true,
                    ThumbUpAlt: true,
                };
            case '2':
                return {
                    PriceCheck: true,
                    VolumeOff: true,
                    Visibility: true,
                    Info: true,
                    PriceChange: true,
                    ThumbUpAlt: true,
                };
            case '3':
                return {
                    PriceCheck: false,
                    VolumeOff: false,
                    Visibility: true,
                    Info: true,
                    PriceChange: false,
                    ThumbUpAlt: true,
                };
            default:
                return {};
        }
    };

    const buttonAccess = getButtonAccessByRole(userRole);

    //mesas sin datos
    const isDataAvailable = !!data && Object.keys(data).length > 0;


    const handlePriceCheckClick = () => {
        setConfirmAction('priceCheck');
        setDialogOpen(true);
        setDialogMessage("¿Está seguro de liberar la Mesa y el cliente ya pagó?");
    };
    const handleThumbUpClick = () => {
        setConfirmAction('thumbUp');
        setDialogOpen(true);
        setDialogMessage("¿Confirma que el pedido está listo?");

    };
    const handleConfirm = () => {
        if (confirmAction === 'priceCheck') {
            if (data.ids) { // Revisa si hay ids en los datos de la mesa.
                axios.post(`${config.baseURL}/actualizalibera`, {ids: data.ids})
                    .then(response => {
                        console.log("Estado actualizado exitosamente", response);
                    })
                    .catch(error => {
                        console.error("Error al actualizar el estado:", error);
                    });
            }
        } else if (confirmAction === 'thumbUp') {
            //console.log('data.ids', data.ids);
            if (data.ids) { // Revisa si hay ids en los datos de la mesa.
                axios.post(`${config.baseURL}/avisococina`, {ids: data.ids})
                    .then(response => {
                        console.log("Estado actualizado exitosamente", response);
                    })
                    .catch(error => {
                        console.error("Error al actualizar el estado:", error);
                    });
            }
        }
        setDialogOpen(false);
        setConfirmAction(null);
    };
    const handleClose = () => {
        setDialogOpen(false);
        setConfirmAction(null);
    };
    const handleVolumeOffClick = () => {
        if (data.ids) { // Revisa si hay ids en los datos de la mesa.
            axios.post(`${config.baseURL}/actualizamesero`, {ids: data.ids})
                .then(response => {
             //       console.log("Estado actualizado exitosamente", response);
                })
                .catch(error => {
                    console.error("Error al actualizar el estado:", error);
                });
        }
    };
    //para el vuelto
    const handleAmountChange = (e) => {
        const amount = parseFloat(e.target.value);
        if (!isNaN(amount) && data.totalpedido) {
            setAmountPaid(amount);
            const totalPedido = parseFloat(data.totalpedido);
            if (amount > totalPedido) {
                setChangeAmount(amount - totalPedido);
            } else {
                setChangeAmount(0);
            }
        } else {
            setAmountPaid("");
            setChangeAmount(0);
        }
    };
    const openChangeModal = () => {
        setShowChangeModal(true);
    };
    const closeChangeModal = () => {
        setShowChangeModal(false);
        setAmountPaid("");
        setChangeAmount(0);
    };
    return (
        <Grid item xs={12}>
        <Card variant="outlined" sx={{bgcolor: cardColor}}>
            <Dialog
                open={showChangeModal}
                onClose={closeChangeModal}
                aria-labelledby="change-modal-title"
            >
                <DialogTitle id="change-modal-title">Vuelto para el Cliente</DialogTitle>
                <DialogContent>
                    <Typography variant={responsiveStyles.typographyVariant}>Total Pedido: {data.totalpedido}</Typography>
                    <DialogContentText>
                        Por favor, introduzca la cantidad pagada por el cliente:
                    </DialogContentText>
                    <TextField
                        autoFocus
                        margin="dense"
                        label="Cantidad Pagada"
                        type="number"
                        fullWidth
                        value={amountPaid}
                        onChange={handleAmountChange}
                        onKeyUp={handleAmountChange}
                    />
                    <Typography variant={responsiveStyles.typographyVariant} sx={{color: "#f1b979"}}>
                        Vuelto: S/.{changeAmount.toFixed(2)}
                    </Typography>
                </DialogContent>
                <DialogActions>
                    <Button onClick={closeChangeModal} color="primary">
                        Cerrar
                    </Button>
                </DialogActions>
            </Dialog>

            <Dialog
                open={dialogOpen}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">{"Confirmación"}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        {dialogMessage}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} color="primary">
                        Cancelar
                    </Button>
                    <Button onClick={handleConfirm} color="success" autoFocus>
                        OK
                    </Button>
                </DialogActions>
            </Dialog>

            <Paper elevation={0}>
                <CardHeader sx={{
                    color: darkMode ? '#f1b979' : '#464c50',
                    backgroundColor: darkMode ? '#464c50' : '#f1b979',
                    padding: '1px'
                }}
                            title={`Mesa ${mesaNum}${data.hora ? ` - Hora: ${data.hora}` : ''}`}
                            titleTypographyProps={{variant: 'caption', fontWeight: 'bold'}}
                />
            </Paper>
            <CardContent>
                <Grid container spacing={1} justifyContent="center" >

                    <Grid item xs={12} >
                        <Typography variant={responsiveStyles.typographyVariant} style={{color: "rgba(150,44,44,0.97)", fontSize: '0.8rem',   fontWeight: 'bold', margin: '-10px'}}>{`${data.usuario ? `${data.usuario}` : ''}`}</Typography>
                    </Grid>
                    <Grid item xs={6}><IconButton size={responsiveStyles.iconSize} onClick={handlePriceCheckClick}
                                                  disabled={!buttonAccess.PriceCheck || !isDataAvailable || data.estadovalidado !== "2"}
                                                  sx={{
                                                      color: darkMode ? '#28a745' : '#28a745',
                                                      backgroundColor: darkMode ? 'rgba(255,255,255,0.46)' : 'rgba(255,255,255,0.46)',
                                                      '&:hover': {backgroundColor: 'rgba(0,0,0,0.09)',}
                                                  }}><PriceCheck/></IconButton></Grid>
                    <Grid item xs={6}><IconButton size={responsiveStyles.iconSize} onClick={handleVolumeOffClick}
                                                  disabled={!buttonAccess.VolumeOff || !isDataAvailable || data.estadovalidado !== "4"}
                                                  sx={{
                                                      color: darkMode ? '#ee5261' : '#ee5261',
                                                      backgroundColor: darkMode ? 'rgba(255,255,255,0.46)' : 'rgba(255,255,255,0.46)',
                                                      '&:hover': {backgroundColor: 'rgba(0,0,0,0.09)',}
                                                  }}><VolumeOff/></IconButton></Grid>
                    <Grid item xs={6}><IconButton size={responsiveStyles.iconSize} disabled={!buttonAccess.Visibility || !isDataAvailable}
                                                  onClick={openPopover} sx={{
                        color: darkMode ? '#4f87be' : '#4f87be',
                        backgroundColor: darkMode ? 'rgba(255,255,255,0.46)' : 'rgba(255,255,255,0.46)',
                        '&:hover': {backgroundColor: 'rgba(0,0,0,0.09)',}
                    }}>
                        <Visibility/>
                    </IconButton>
                        <InfoPopover
                            isOpen={isOpen}
                            anchorEl={anchorEl}
                            closePopover={closePopover}
                            data={data}
                            mesaNum={mesaNum}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <IconButton
                            size={responsiveStyles.iconSize}
                            disabled={!buttonAccess.ThumbUpAlt || !isDataAvailable}
                            onClick={handleThumbUpClick}
                            sx={{
                                color: darkMode ? '#dca90b' : '#dca90b',
                                backgroundColor: darkMode ? 'rgba(255,255,255,0.46)' : 'rgba(255,255,255,0.46)',
                                '&:hover': {backgroundColor: 'rgba(0,0,0,0.09)',}
                            }}
                        >
                            <ThumbUpAlt/>
                        </IconButton>
                    </Grid>
                    <Grid item xs={6}>
                        <IconButton size={responsiveStyles.iconSize} onClick={openPopoverDetail}  disabled={!buttonAccess.Info || !isDataAvailable} sx={{
                            color: darkMode ? '#824ec7' : '#824ec7',
                            backgroundColor: darkMode ? 'rgba(255,255,255,0.46)' : 'rgba(255,255,255,0.46)',
                            '&:hover': {backgroundColor: 'rgba(0,0,0,0.09)',}
                        }}><Info/></IconButton>

                        <DetailPopover
                            isOpenDetail={isOpenDetail}
                            anchorElDetail={anchorElDetail}
                            closePopoverDetail={closePopoverDetail}
                            data={data}
                            mesaNum={mesaNum}
                            datadetail={datadetail}
                        />
                    </Grid>
                    <Grid item xs={6}><IconButton onClick={openChangeModal} disabled={!buttonAccess.PriceChange || !isDataAvailable} sx={{
                        color: darkMode ? '#14a2b8' : '#14a2b8',
                        backgroundColor: darkMode ? 'rgba(255,255,255,0.46)' : 'rgba(255,255,255,0.46)',
                        '&:hover': {backgroundColor: 'rgba(0,0,0,0.09)',}
                    }}><PriceChange/></IconButton></Grid>
                </Grid>
            </CardContent>
        </Card>
        </Grid>
    );
});

export default Mesa;

