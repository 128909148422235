import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Table, TableBody, TableCell, TableHead, TableRow, Paper, CircularProgress, Typography } from '@mui/material';
import config from "../system/config";


function ConsumoDia() {
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        axios.get(`${config.baseURL}/consumodia`)
            .then(response => {
                if (response.data.status) {
                    setData(response.data.data);
                    setLoading(false);
                } else {
                    setError(response.data.message);
                    setLoading(false);
                }
            })
            .catch(err => {
                setError('Error al obtener los datos.');
                setLoading(false);
            });
    }, []);

    if (loading) return <CircularProgress />;
    if (error) return <Typography variant="h6" color="error">Error: {error}</Typography>;

    return (
        <Paper elevation={3} style={{ overflowX: 'auto' }}>
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell style={{ fontWeight: 'bold', fontSize: '1.2em' }} >Nombre</TableCell>
                        <TableCell style={{ fontWeight: 'bold', fontSize: '1.2em' }} >Stock</TableCell>
                        <TableCell style={{ fontWeight: 'bold', fontSize: '1.2em' }} >Descuento</TableCell>
                        <TableCell style={{ fontWeight: 'bold', fontSize: '1.2em' }} >Restante</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {data.map(item => (
                        <TableRow key={item.nombre} style={{ backgroundColor: item.restante <= 0 ? '#c7a3dc' : 'transparent' }}>
                            <TableCell>{item.nombre}</TableCell>
                            <TableCell>{item.stock}</TableCell>
                            <TableCell>{item.descuento}</TableCell>
                            <TableCell>{item.restante}</TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </Paper>
    );
}

export default ConsumoDia;
