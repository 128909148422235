import React, { useState, useEffect, useCallback, useRef } from 'react';
import {
    Box, Grid, TextField, FormControl, InputLabel, Select, MenuItem, Button, Container, Typography,
    useTheme, useMediaQuery
} from '@mui/material';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider, TimePicker } from '@mui/x-date-pickers';
import { styled } from '@mui/system';
import { VerificarVentaModal } from '../components/ModalVerifyVenta';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ChecklistIcon from '@mui/icons-material/Checklist';
import axios from 'axios';
import config from "../system/config";
import ProgressBarStockVertical from './StockProgressBarVertical';
import SearchIcon from '@mui/icons-material/Pageview';
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import { ConsumoDiaData } from '../components/ConsumoDiaData';

const ResponsiveContainer = styled(Container)(({ theme }) => ({
    [theme.breakpoints.down('sm')]: {
        maxWidth: '100%',
    },
    [theme.breakpoints.between('sm', 'md')]: {
        maxWidth: '100%',
    },
    [theme.breakpoints.up('md')]: {
        maxWidth: '40%',
    },
}));

function Pedido({ darkMode }) {
    const [pedido, setPedido] = useState([]);
    const [isSaving, setIsSaving] = useState(false);
    const [reserva, setReserva] = useState("MESA");
    const theme = useTheme();
    const matches = useMediaQuery(theme.breakpoints.down('md'));
    const darkModeColor = { marginTop: '0.8rem', color: darkMode ? '#000000' : '#000000' };
    const darkModeColorPlus = { paddingBottom: '0.8rem', fontSize: '0.7rem', color: darkMode ? '#000000' : '#000000' };
    const darkModeColorTitle = { fontSize: '0.9rem', color: darkMode ? '#000000' : '#000000' };
    const darkModeColorPlusTypo = {
        marginTop: '0.55rem',
        fontSize: '0.67rem',
        color: darkMode ? '#000000' : '#000000',
        fontWeight: 'bold'
    };
    const [selectedTime, setSelectedTime] = useState(new Date());
    const [openModal, setOpenModal] = useState(false);
    const [detallesVenta, setDetallesVenta] = useState({});
    const [mesa, setMesa] = useState("0");
    const [showComponentModal, setShowComponentModal] = useState(false);
    const clienteRef = useRef();
    const observacionesRef = useRef();
    const telefonoRef = useRef();
    const direccionRef = useRef();
    const [updatedProducts, setUpdatedProducts] = useState([]);

    const handleMesaChange = useCallback((event) => {
        setMesa(event.target.value);
    }, []);

    const handleSelectChange = useCallback((index, name, value) => {
        setPedido((currentPedido) => {
            const newPedido = [...currentPedido];
            newPedido[index] = { ...newPedido[index], [name]: value };
            return newPedido;
        });
    }, []);

    const handleCloseModal = useCallback(() => {
        setOpenModal(false);
    }, []);

    useEffect(() => {
        (async () => {
            const products = await ConsumoDiaData();
            setUpdatedProducts(products);
        })();
    }, []);

    useEffect(() => {
        axios.get(`${config.baseURL}/getProductos`)
            .then((response) => {
                const productosAPI = response.data.data.data;
                const productosFormateados = productosAPI.map((producto) => ({
                    plato: producto.producto,
                    cantidad: producto.cantidad.split(',').map(Number),
                    tipo: producto.tipo.split(','),
                    cantidadSeleccionada: producto.cantidadseleccionada,
                    tipoSeleccionado: producto.tiposeleccionado,
                    color: producto.color,
                    precio: producto.precio
                }));
                setPedido(productosFormateados);
            })
            .catch((error) => {
                console.error("Error al recuperar los productos", error);
            });
    }, []);

    useEffect(() => {
        const interval = setInterval(() => {
            if (reserva === "MESA") {
                setSelectedTime(new Date());
            }
        }, 30000);

        return () => clearInterval(interval);
    }, [reserva]);

    const handleVerify = useCallback(() => {
        const clienteActual = clienteRef.current ? clienteRef.current.value : '';
        const observacionesActual = observacionesRef.current ? observacionesRef.current.value : '';
        const telefonoActual = telefonoRef.current ? telefonoRef.current.value : '';
        const direccionActual = direccionRef.current ? direccionRef.current.value : '';

        setOpenModal(false);

        if (mesa <= 0) {
            toast.error('Por favor, seleccione una mesa.');
            return;
        }

        if (clienteActual.trim() === "") {
            toast.error('Por favor, defina el nombre del cliente.');
            return;
        }

        if (!pedido.some((plato) => Number(plato.cantidadSeleccionada) > 0)) {
            toast.warning('Por favor, añada al menos un plato con cantidad mayor a 0.');
            return;
        }

        const totalPrecio = pedido
            .filter((plato) => Number(plato.cantidadSeleccionada) > 0)
            .reduce((sum, plato) => {
                if (plato.plato === "Adicionales") {
                    return sum + Number(plato.tipoSeleccionado);
                } else {
                    return sum + (plato.cantidadSeleccionada * plato.precio);
                }
            }, 0);

        const detallesPedido = pedido
            .filter((plato) => Number(plato.cantidadSeleccionada) > 0)
            .map((plato) => {
                const isAdicional = plato.plato === "Adicionales";
                return {
                    nombrePlato: plato.plato,
                    cantidad: !isAdicional ? plato.cantidadSeleccionada : 1,
                    tipo: plato.tipoSeleccionado,
                    precio: plato.precio.toString(),
                    totalindividual: !isAdicional
                        ? (plato.cantidadSeleccionada * plato.precio).toString()
                        : (1 * plato.cantidadSeleccionada),
                    totalPrecio: totalPrecio.toString()
                };
            });

        const formattedTime = `${selectedTime.getHours()}:${String(selectedTime.getMinutes()).padStart(2, '0')}`;

        setDetallesVenta({
            cliente: clienteActual,
            reserva,
            mesa,
            hora: formattedTime,
            observaciones: observacionesActual,
            telefono: telefonoActual,
            direccion: direccionActual,
            detallesPedido
        });
    }, [mesa, pedido, reserva, selectedTime]);

    useEffect(() => {
        if (Object.keys(detallesVenta).length > 0 && detallesVenta.cliente) {
            setOpenModal(true);
        }
    }, [detallesVenta]);

    const stockConsumptionRules = {
        'Cecina personal': { 'Cecina': 1 },
        'Cecina familiar': { 'Cecina': 3 },
        '1/4 cuy frito': { 'Cuy': 0.25 },
        '1/4 cuy guisado': { 'Cuy': 0.25 },
        '1/2 cuy frito': { 'Cuy': 0.5 },
        '1/2 cuy guisado': { 'Cuy': 0.5 },
        'Entero cuy frito': { 'Cuy': 1 },
        'Entero cuy guisado': { 'Cuy': 1 },
        'Mechadito a la Mechita': { 'Mechadito': 1 },
    };

    const calculateStockConsumption = (productName, quantity) => {
        const consumptionRule = stockConsumptionRules[productName];
        if (!consumptionRule) return { stockProduct: null, stockConsumed: null };

        const stockProduct = Object.keys(consumptionRule)[0];
        const stockConsumed = consumptionRule[stockProduct] * quantity;

        return { stockProduct, stockConsumed };
    };

    const isProductAvailable = async (productName, quantityNeeded) => {
        try {
            const { stockProduct, stockConsumed } = calculateStockConsumption(productName, quantityNeeded);

            if (stockProduct === null) return { isAvailable: true, productName: productName, restante: null };

            const product = updatedProducts.find((p) => p.nombre === stockProduct);
            return {
                isAvailable: product && product.restante >= stockConsumed,
                productName: productName,
                restante: product ? product.restante : 0,
            };
        } catch (error) {
            return { isAvailable: false, productName: productName, restante: 0 };
        }


    };

    const handleSave = async () => {
        if (isSaving) {
            return;
        }

        setIsSaving(true);

        const totalStockConsumedPerProduct = {};

        const availabilityChecks = detallesVenta.detallesPedido.map((item) => {
            const consumption = calculateStockConsumption(item.nombrePlato, item.cantidad);
            if (consumption && consumption.stockProduct !== null) {
                totalStockConsumedPerProduct[consumption.stockProduct] = (totalStockConsumedPerProduct[consumption.stockProduct] || 0) + consumption.stockConsumed;
            }
            return isProductAvailable(item.nombrePlato, item.cantidad);
        });

        const totalStockAvailability = Object.entries(totalStockConsumedPerProduct).map(async ([product, totalStockConsumed]) => {
            const productInfo = updatedProducts.find((p) => p.nombre === product);
            if (!productInfo || productInfo.restante < totalStockConsumed) {
                return { isAvailable: false, productName: product };
            }
            return { isAvailable: true, productName: product };
        });

        const availabilityResults = await Promise.all([...availabilityChecks, ...totalStockAvailability]);

        const unavailableProducts = availabilityResults.filter((result) => !result.isAvailable && result.productName !== null);

        if (unavailableProducts.length > 0) {
            const unavailableProductNames = unavailableProducts.map((product) => product.productName).join(', ');
            toast.error(`No hay stock suficiente de: ${unavailableProductNames}. Por favor, ajuste su pedido.`, {
                autoClose: 7000,
            });
            setIsSaving(false);
            return;
        }

        const fullname = localStorage.getItem('fullname');

        const ventaData = {
            userfullname: fullname,
            ...detallesVenta,
        };

        axios.post(`${config.baseURL}/enviaventa`, ventaData)
            .then(() => {
                toast.success("Venta guardada con éxito!");
                setPedido((pedido) =>
                    pedido.map((plato) => ({
                        ...plato,
                        cantidadSeleccionada: 0,
                    }))
                );
                setReserva("MESA");
                setMesa("0");
                setSelectedTime(new Date());
                if (clienteRef.current) clienteRef.current.value = ''; // Limpiar el campo cliente
                if (observacionesRef.current) observacionesRef.current.value = ''; // Limpiar el campo observaciones
                if (telefonoRef.current) telefonoRef.current.value = ''; // Limpiar el campo teléfono
                if (direccionRef.current) direccionRef.current.value = ''; // Limpiar el campo dirección

            })
            .catch((error) => {
                console.error("Error al guardar la venta:", error);
                toast.error("Error al guardar la venta. Por favor, inténtelo de nuevo.");
            })
            .finally(() => {
                setIsSaving(false);
                handleCloseModal();
            });
    };

    return (
        <ResponsiveContainer disableGutters={matches} sx={{ paddingTop: '1%' }}>
            <Box>
                <Dialog open={showComponentModal} onClose={() => setShowComponentModal(false)}>
                    <DialogContent>
                        <Typography variant="h6">Stock de productos</Typography>
                        <ProgressBarStockVertical />
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={() => setShowComponentModal(false)} color="primary">
                            Cerrar
                        </Button>
                    </DialogActions>
                </Dialog>

                <Grid container spacing={1} sx={{ backgroundColor: '#bbc3ce' }}>
                    <Grid container justifyContent="center" alignItems="center" style={{ position: 'relative', marginBottom: '-40px' }}>
                        <Grid item xs={12} style={{ textAlign: 'center' }}>
                            <Button onClick={() => setShowComponentModal(true)}>
                                <SearchIcon size="small" color="secondary" fontSize="large" />
                            </Button>
                        </Grid>
                    </Grid>
                    <Grid item xs={6} sm={6}>
                        <FormControl sx={{ m: 1, width: '90%', marginBottom: '-0.8rem' }} size="small">
                            <InputLabel id="label-mesa" sx={darkModeColorTitle}>Mesa:</InputLabel>
                            <Select
                                labelId="label-mesa"
                                id="mesa"
                                sx={darkModeColor}
                                size="small"
                                value={mesa}
                                onChange={handleMesaChange}
                            >
                                {[...Array(49).keys()].map((num) => (
                                    <MenuItem key={num} value={num}>{num}</MenuItem>
                                ))}
                            </Select>
                        </FormControl>

                        <FormControl sx={{ m: 1, width: '90%' }} size="small">
                            <TextField
                                id="cliente"
                                label="Cliente"
                                sx={darkModeColor}
                                size="small"
                                placeholder="Cliente"
                                inputRef={clienteRef}
                            />
                        </FormControl>
                    </Grid>
                    <Grid item xs={6} sm={6}>
                        <FormControl sx={{ m: 1, width: '90%', marginBottom: '-0.25rem' }} size="small">
                            <InputLabel id="reserva" sx={darkModeColorTitle}>Tipo:</InputLabel>
                            <Select
                                defaultValue="MESA"
                                value={reserva}
                                onChange={(e) => setReserva(e.target.value)}
                                id="reserva"
                                size="small"
                                sx={darkModeColor}
                            >
                                <MenuItem value="MESA">MESA</MenuItem>
                                <MenuItem value="RESERVA">RESERVA</MenuItem>
                                <MenuItem value="DELIVERY">DELIVERY</MenuItem>
                                <MenuItem value="RECOGER">RECOGER</MenuItem>
                            </Select>
                        </FormControl>
                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                            <TimePicker
                                readOnly={reserva === "MESA"}
                                sx={{ m: 1.5, width: '90%', marginLeft: '0.5rem' }}
                                slotProps={{ textField: { size: "small" } }}
                                value={selectedTime}
                                onChange={(newTime) => {
                                    setSelectedTime(newTime);
                                }}
                            />
                        </LocalizationProvider>
                    </Grid>
                </Grid>

                {pedido.map((plato, index) => (
                    <Grid container spacing={1} key={index} sx={{ backgroundColor: plato.color }}>
                        <Grid item xs={2}>
                            <Typography sx={darkModeColorPlusTypo}>{plato.plato}</Typography>
                        </Grid>
                        <Grid item xs={5}>
                            <FormControl fullWidth sx={{ m: 1, minWidth: 120 }} size="small">
                                {plato.plato !== "Adicionales" && (
                                    <InputLabel sx={darkModeColorPlus}>Cantidad</InputLabel>
                                )}
                                {plato.plato === "Adicionales" ? (
                                    <TextField
                                        sx={{
                                            ...darkModeColor,
                                            marginTop: '0.05rem',
                                            '& input': {
                                                height: '2.8rem',
                                                padding: '0 14px',
                                            },
                                        }}
                                        type="number"
                                        value={plato.cantidadSeleccionada}
                                        onChange={(event) => handleSelectChange(index, "cantidadSeleccionada", event.target.value)}
                                        size="small"
                                        placeholder="Precio"
                                    />
                                ) : (
                                    <Select
                                        value={plato.cantidadSeleccionada}
                                        onChange={(event) => handleSelectChange(index, "cantidadSeleccionada", event.target.value)}
                                        sx={darkModeColorPlus}
                                        style={{ width: '100%' }}
                                    >
                                        {plato.cantidad.map((num, numIndex) =>
                                            <MenuItem key={numIndex} value={num}>{num}</MenuItem>
                                        )}
                                    </Select>
                                )}
                            </FormControl>
                        </Grid>
                        <Grid item xs={5}>
                            <FormControl fullWidth sx={{ m: 1, minWidth: 100 }} size="small">
                                <InputLabel sx={darkModeColorPlus}>Tipo</InputLabel>
                                <Select
                                    value={plato.tipoSeleccionado}
                                    onChange={(event) => handleSelectChange(index, "tipoSeleccionado", event.target.value)}
                                    sx={darkModeColorPlus}
                                    style={{ width: '96%' }}
                                >
                                    {plato.tipo.map((type, typeIndex) =>
                                        <MenuItem key={typeIndex} value={type}>{type}</MenuItem>
                                    )}
                                </Select>
                            </FormControl>
                        </Grid>
                    </Grid>
                ))}
                <Grid item xs={6} sm={6}>
                    <TextField
                        multiline
                        rows={3}
                        fullWidth
                        label="Observaciones"
                        defaultValue=""
                        inputRef={observacionesRef}
                        sx={{ marginBottom: '0.2rem', marginTop: '0.2rem' }}
                    />
                </Grid>
                <Grid item xs={6} sm={6}>
                    <TextField
                        fullWidth
                        label="Teléfono"
                        defaultValue=""
                        inputRef={telefonoRef}
                        sx={{ marginBottom: '0.2rem', marginTop: '0.2rem' }}
                    />
                </Grid>
                <Grid item xs={6} sm={6}>
                    <TextField
                        multiline
                        rows={3}
                        fullWidth
                        label="Dirección"
                        defaultValue=""
                        inputRef={direccionRef}
                        sx={{ marginBottom: '0.2rem', marginTop: '0.2rem' }}
                    />
                </Grid>
            </Box>
            <Box
                display="flex"
                justifyContent="center"
                mt={2}
            >
                <Button
                    sx={{ marginTop: '-0.9rem' }}
                    variant="contained"
                    color="success"
                    onClick={handleVerify}
                    startIcon={<ChecklistIcon />}
                >
                    Verificar
                </Button>


            </Box>
            <VerificarVentaModal
                key={JSON.stringify(detallesVenta)}
                open={openModal}
                detallesVenta={detallesVenta}
                onSave={handleSave}
                isSaving={isSaving}
                onClose={handleCloseModal}
            />
            <ToastContainer position="top-right" autoClose={2000} hideProgressBar={false} newestOnTop={false}
                            closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover />
        </ResponsiveContainer>
    );
}

export default Pedido;
