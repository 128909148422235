// index.js
import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { AppProvider } from './contexts/AppContext';

function Main() {
    /*
    const fullname = localStorage.getItem('fullname');
    const userId = localStorage.getItem('userId');
    const userRole = localStorage.getItem('userRole');
    const userManager = localStorage.getItem('userManager');
    console.log('fullnamevalor', fullname, userId, userRole, userManager);
    */

// CACHE - serviceWorker PARA ACELERAR LA CARGA DEL WEBVIEW EN ENTORNOS OFLINE - MEJORA CACHE
    //if ('serviceWorker' in navigator) {
        //navigator.serviceWorker.register('/service-worker.js').then(function(registration) {
            //console.log('Service Worker registrado con éxito:', registration);
       // }, function(error) {
            //console.log('Registro de Service Worker fallido:', error);
      //  });
   // }

    return <App />;
}

const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
    <React.StrictMode>
        <AppProvider>
            <Main />
        </AppProvider>
    </React.StrictMode>,
);

reportWebVitals();
