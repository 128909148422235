import React, { useState, useRef } from 'react';
import {Button, TextField, Container, Typography, FormControl, InputLabel, Select, MenuItem} from '@mui/material';
import QRCode from 'qrcode.react';
import axios from "axios";
import config from "../system/config";
import {Box} from "@mui/system";
import {ToastContainer, toast} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import DialogTitle from "@mui/material/DialogTitle";
const QRCodeGenerator = () => {
    const [input, setInput] = useState('');
    const [cliente, setCliente] = useState('');
    const [qrValue, setQrValue] = useState(null);
    const qrRef = useRef(null);
    const [descripcion, setDescripcion] = React.useState('Cliente Frecuente');
    const [producto, setProducto] = React.useState('Quengococha');
    const [openDialog, setOpenDialog] = useState(false);
    // Manejadores para los campos de texto

    const handleConfirmGenerate = () => {
        setOpenDialog(false); // cierra el diálogo
        handleGenerateQR(); // llama a tu función de generación de QR existente
    };

    // Esta función se llama cuando el usuario cancela la generación del cupón
    const handleCloseDialog = () => {
        setOpenDialog(false); // cierra el diálogo
    };

    // Esta función abre el diálogo
    const handleOpenDialog = () => {
        setOpenDialog(true);
    };
    const handleInputChange = (event) => {
        setInput(event.target.value);
    };

    const handleClienteChange = (event) => {
        setCliente(event.target.value);
    };

    const handleProductoChange = (event) => {
        setProducto(event.target.value);
    };

    const handleDescripcionChange = (event) => {
        setDescripcion(event.target.value);
    };

    const downloadQR = () => {
        // obtener el canvas del QR code
        const canvas = qrRef.current.querySelector("canvas");
        // crear un elemento "a" y luego clickearlo para descargar la imagen
        const pngUrl = canvas
            .toDataURL("image/png")
            .replace("image/png", "image/octet-stream");

        let downloadLink = document.createElement("a");
        downloadLink.href = pngUrl;

        // Obtener la fecha y hora actual, y formatearlas para el nombre del archivo
        const date = new Date();
        const formattedDate = `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}_${date.getHours()}-${date.getMinutes()}-${date.getSeconds()}`;

        // Usar el valor de 'input' que es el DNI ingresado por el usuario
        const filename = `cupon_${input}`;

        downloadLink.download = `${filename}_${formattedDate}.png`;
        document.body.appendChild(downloadLink);
        downloadLink.click();
        document.body.removeChild(downloadLink);
    };


    const handleGenerateQR = () => {
        // Validaciones básicas podrían ir aquí (por ejemplo, verificar si algún campo está vacío)
        const fullname = localStorage.getItem('fullname');

        // Crear el objeto de datos del cupón
        const cuponData = {
            dni: input, // esto asume que tu backend espera un campo 'dni'
            cliente: cliente,
            producto: producto,
            descripcion: descripcion,
            usuario: fullname
        };

        // Llamada al API para crear/actualizar el cupón
        axios.post(`${config.baseURL}/cupon`, cuponData)
            .then(response => {
                if (response.data.status) {
                    // Si el cupón se procesó exitosamente, generamos el QR
                    setQrValue(input); // o cualquier dato que se quiera representar en el QR

                    // Aquí mostramos el toast antes de la descarga
                    toast.success("Cupón generado exitosamente!", {
                        position: toast.POSITION.TOP_CENTER
                    });
                } else {
                    // Manejo de errores devueltos por el servidor (e.g., cupón ya existe)
                    console.error(response.data.message);
                    toast.error("Error al generar el cupón: " + response.data.message, {
                        position: toast.POSITION.TOP_CENTER
                    });
                }
            })
            .catch(error => {
                // Manejo de errores de la solicitud
                console.error('Error al crear/actualizar el cupón:', error);
                toast.error("Error al generar el cupón: " + error.toString(), {
                    position: toast.POSITION.TOP_CENTER
                });
            });
    };

    return (
        <Container maxWidth="sm">
            <Typography variant="h4" gutterBottom>
                Generador cupones
            </Typography>

            <TextField
                fullWidth
                label="Ingresa el DNI"
                value={input}
                onChange={handleInputChange}
                type="number"
                margin="normal"
                variant="outlined"
            />

            <TextField
                fullWidth
                label="Cliente"
                value={cliente}
                onChange={handleClienteChange}
                margin="normal"
                variant="outlined"
            />

            <Box sx={{ minWidth: 120 }}>
                <FormControl fullWidth margin="normal" variant="outlined">
                    <InputLabel id="producto-label">Producto</InputLabel>
                    <Select
                        labelId="producto-label"
                        value={producto}
                        onChange={handleProductoChange}
                        label="Producto"
                    >
                        {/* Añade tus opciones aquí. Son elementos <MenuItem> */}
                        <MenuItem value={'Quengococha'}>Quengococha</MenuItem>
                        <MenuItem value={'Yahuarcocha'}>Yahuarcocha</MenuItem>
                        <MenuItem value={'Vino'}>Vino</MenuItem>
                        <MenuItem value={'Peña del Olvido'}>Peña del Olvido</MenuItem>
                        {/*<MenuItem value={'1/4 Cuy'}>1/4 Cuy</MenuItem>*/}
                        {/*<MenuItem value={'Cecina Personal'}>Cecina Personal</MenuItem>*/}

                        {/* ... más opciones ... */}
                    </Select>
                </FormControl>
            </Box>

            <Box sx={{ minWidth: 120 }}>
                <FormControl fullWidth margin="normal" variant="outlined">
                    <InputLabel id="descripcion-label">Descripción</InputLabel>
                    <Select
                        labelId="descripcion-label"
                        value={descripcion}
                        onChange={handleDescripcionChange}
                        label="Descripción"
                    >
                        {/* Añade tus opciones aquí. Son elementos <MenuItem> */}

                        <MenuItem value={'Cliente Frecuente'}>Cliente Frecuente</MenuItem>
                        <MenuItem value={'Cortesía'}>Cortesía</MenuItem>
                        {/* ... más opciones ... */}
                    </Select>
                </FormControl>
            </Box>


            <Button
                variant="contained"
                color="primary"
                fullWidth
                onClick={handleOpenDialog} // Cambiado para abrir el diálogo en lugar de generar directamente el QR
                style={{ marginTop: '20px' }}
            >
                Generar Código QR
            </Button>

            {qrValue && (
                <>
                    <div style={{ display: 'flex', justifyContent: 'center', marginTop: '20px' }}>
                        <div ref={qrRef}>
                            <QRCode value={qrValue.toString()} size={256} />
                        </div>
                    </div>
                    <div style={{ display: 'flex', justifyContent: 'center', marginTop: '20px' }}>
                        <Button
                            variant="contained"
                            color="secondary"
                            onClick={downloadQR}
                        >
                            Descargar Cupón en QR
                        </Button>
                    </div>
                </>
            )}

            <Dialog
                open={openDialog}
                onClose={handleCloseDialog}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    {"Confirmar generación de cupón"}
                </DialogTitle>
                <DialogContent>
                    <Typography variant="body1">¿Estás seguro de que deseas generar este cupón?</Typography>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseDialog} color="primary">
                        Cancelar
                    </Button>
                    <Button onClick={handleConfirmGenerate} color="primary" autoFocus>
                        Confirmar
                    </Button>
                </DialogActions>
            </Dialog>


            {/* Asegúrate de incluir ToastContainer en tu componente */}
            <ToastContainer />
        </Container>
    );
}

export default QRCodeGenerator;
