import React, { useEffect, useState } from 'react';
import axios from 'axios';
import ReactECharts from 'echarts-for-react';
import config from "../system/config";
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import {Grid, TextField, Typography} from "@mui/material";
import dayjs from 'dayjs';
import { DataGrid } from '@mui/x-data-grid';
import '../HeaderGrid.css';
import 'dayjs/locale/es';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
dayjs.extend(utc); // Usa el plugin utc
dayjs.extend(timezone); // Usa el plugin timezone
dayjs.locale('es'); // Establece el locale de dayjs a español

function VentaProductoCantidadDiaChart() {
    const [data, setData] = useState([]);
    const [startDate, setStartDate] = useState(dayjs().tz(`${config.timezonewislla}`));
    const userRole = Number(localStorage.getItem('userRole'));
    const [formattedDate, setFormattedDate] = useState(dayjs().locale('es').format('dddd D [de] MMMM [de] YYYY'));
    const [totalSum, setTotalSum] = useState(0);
    const isRole0or1 = +userRole === 0 || +userRole === 1;
    const isRole2 = +userRole === 2;

    // Function to handle date change with restriction for role 2
    const handleRestrictedDateChange = (date) => {
        const today = dayjs();
        const sevenDaysAgo = today.subtract(7, 'days');

        if (date.isAfter(sevenDaysAgo) && date.isBefore(today.add(1, 'day'))) {
            handleDateChange(date);
        } else {
            // Handle the case where the date is out of the allowed range
            alert('Para el rol 2, solo puedes seleccionar fechas de hasta 7 días atrás.');
        }
    };


    useEffect(() => {
        const formattedDate = startDate.format('YYYY-MM-DD');
        axios.get(`${config.baseURL}/ventaproductocantidaddia?inicio=${formattedDate}`)
            .then(response => {
                if (response.data && response.data.status && response.data.data) {
                    const dataWithIds = response.data.data.map((item, index) => ({ ...item, id: index.toString() }));
                    setData(dataWithIds);

                    // Calcula la suma total aquí
                    if (Array.isArray(response.data.data)) {
                        const sum = response.data.data.reduce((acc, item) => acc + parseFloat(item.total), 0);
                        setTotalSum(sum);
                    } else {
                        console.error('response.data.data is not an array:', response.data.data);
                    }
                } else {
                    console.log('Unexpected response format:', response.data);
                }

            })
            .catch(error => {
                console.error('Error fetching data from ventaproductocantidaddia:', error);
            });
    }, [startDate]);


    const handleDateChange = (date) => {
        setStartDate(dayjs(date));
        setFormattedDate(dayjs(date).locale('es').format('dddd D [de] MMMM [de] YYYY'));
    }

    const option = {
        tooltip: {
            trigger: 'axis',
            axisPointer: {
                type: 'shadow'
            }
        },
        grid: {
            left: '3%',
            right: '4%',
            bottom: '3%',
            containLabel: true
        },
        xAxis: {
            type: 'value',
            boundaryGap: [0, 0.01]
        },
        yAxis: {
            type: 'category',
            data: data.map(item => item.producto) // Mapea los nombres de los productos como categorías en el eje Y
        },
        series: [
            {
                name: 'Cantidad',
                type: 'bar',
                data: data.map(item => item.total),
                label: {
                    show: true,
                    position: 'right',
                    formatter: (params) => {
                        const index = params.dataIndex;
                        return `{red|${data[index].cantidad} unidades}\n s/. ${data[index].total} `;
                    },
                    rich: {
                        red: {
                            color: '#c94949',
                            fontWeight: 'bold'
                        }
                    }
                }
            },

        ]
    };


    return (
        <div style={{ width: '96%', margin: '0 auto', marginTop: '15px' }}>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
                <Grid container alignItems="center">
                    <Grid item xs={12} sm={2}>
                        <DatePicker
                            label="Fecha de inicio"
                            value={startDate}
                            readOnly={!isRole0or1 && !isRole2}
                            onChange={isRole2 ? handleRestrictedDateChange : handleDateChange}
                            shouldDisableDate={(date) => isRole2 && date.isBefore(dayjs().subtract(7, 'days'))}
                            textField={(params) => <TextField {...params} fullWidth />}
                        />
                    </Grid>
                    <Grid item xs={12} sm={10}>
                        <Typography variant="h6">{formattedDate}</Typography>

                        {/* Renderiza la suma total aquí */}
                        <Typography variant="h6">Total: s/. {totalSum.toFixed(2)}</Typography>
                    </Grid>
                </Grid>
            </LocalizationProvider>


            <Grid container>
                <Grid item xs={12} sm={12} style={{ display: 'flex', justifyContent: 'center' }}>
                    <div style={{ overflowX: 'auto', width: '100%' }}>
                        <ReactECharts option={option} style={{ height: '600px', minWidth: '1200px' }} />
                    </div>
                </Grid>
                <Grid item xs={12} sm={12} style={{ display: 'flex', justifyContent: 'center' }}>
                    <div style={{ height: 500, width: '100%', marginTop: '20px' }}>
                        <DataGrid
                            className="header-data-grid"
                            rows={data}
                            columns={[
                                { field: 'producto', headerName: 'Producto', minWidth: 140, flex: 1 },
                                { field: 'cantidad', headerName: 'Cantidad', type: 'number', minWidth: 140, flex: 1 },
                                { field: 'total', headerName: 'Total', type: 'number',minWidth: 140, flex: 1  }
                            ]}
                            pageSize={5}
                            rowsPerPageOptions={[5]}
                            pagination
                        />
                    </div>
                </Grid>
            </Grid>



            {/* Aquí agregas tu DataGrid */}

        </div>
    );
}

export default VentaProductoCantidadDiaChart;
