import React from 'react';
import {
    Popover, CardHeader, CardContent, Typography, TableContainer,
    Paper, Table, TableHead, TableRow, TableCell, TableBody, Button
} from '@mui/material';
import logoImage from '../assets/icons/logowislla.png';

const TableDataRow = ({ producto, total, descripcion, cantidad, estado }) => (
    <TableRow>
        <TableCell sx={{fontSize: '0.7rem'}}>{producto}</TableCell>
        <TableCell sx={{fontSize: '0.7rem'}}>{cantidad}</TableCell>
        <TableCell sx={{fontSize: '0.7rem'}}>{descripcion}</TableCell>
        <TableCell className="no-print" sx={{fontSize: '0.7rem'}}>{estado}</TableCell>
        <TableCell sx={{fontSize: '0.7rem'}}>{total}</TableCell>
    </TableRow>
);

const InfoPopover = ({ isOpen, anchorEl, closePopover, data, mesaNum }) => {





    const handlePrint = () => {
        const printContents = document.getElementById('print-area').innerHTML;
        const printFrame = document.createElement('iframe');
        printFrame.style.margin = '0';
        printFrame.style.position = 'absolute';
        printFrame.style.width = '0';
        printFrame.style.height = '0';
        printFrame.style.border = '0';
        printFrame.id = 'print-frame';

        document.body.appendChild(printFrame);

        const websiteUrl = 'www.lawislla.com'; // Reemplaza con tu URL real

        const frasesInspiradoras = {
            frase1: "Recuerda los días soleados, incluso en las noches más oscuras.",
            frase2: "Cada risa es un eco de momentos felices.",
            frase3: "Mira hacia atrás con nostalgia, pero corre hacia adelante con esperanza.",
            frase4: "Las estrellas brillan más cuando recordamos nuestros sueños de niñez.",
            frase5: "Baila al ritmo de tus victorias pasadas y futuras alegrías.",
            frase6: "Guarda cada recuerdo feliz como un tesoro en el corazón.",
            frase7: "Deja que la esperanza sea la brújula en tu viaje de la vida.",
            frase8: "Sonríe al pasado, y el futuro sonreirá contigo.",
            frase9: "Cada día es una página nueva en el libro de tus recuerdos felices.",
            frase10: "Abraza el presente con la alegría de los buenos viejos tiempos.",
            frase11: "Cada amanecer trae la melodía de nuevas posibilidades.",
            frase12: "Una sonrisa es el reflejo del alma danzando de felicidad.",
            frase13: "Las huellas de ayer pavimentan el camino hacia mañana.",
            frase14: "Un corazón alegre es un imán para los milagros diarios.",
            frase15: "Revive la magia de los pequeños momentos olvidados.",
            frase16: "Que cada paso que des sea un salto hacia tus sueños.",
            frase17: "En el lienzo de la vida, tú eres el artista de tu felicidad.",
            frase18: "El eco de las risas pasadas ilumina el futuro.",
            frase19: "El brillo en tus ojos es el reflejo de tus logros futuros.",
            frase20: "Cosecha los recuerdos dulces para endulzar el presente."

        };

        const fraseAleatoria = frasesInspiradoras['frase' + (Math.floor(Math.random() * 20) + 1)];

        const printWindow = printFrame.contentWindow;

        printWindow.document.open();
        printWindow.document.write(`
    <html>
        <head>
        <style>
        body {
          width: 55.1mm; /* 95% de 58mm */
          font-family: 'Roboto', sans-serif; 
          line-height: 1.2;
          font-size: 18px;
          font-weight: bold; /* Todo en negrita */
        }
        table {
          width: 100%;
          border-collapse: collapse;
        }
        th, td {
          border: 1px solid black;
          padding: 1px;
          font-size: 14px;
        }
        .no-print {
          display: none;
        }
        .title, .subtitle {
          text-align: center;
          padding-bottom: 2px;
        }
        .logo {
            display: block;
            margin: 0 auto;
            max-width: 100%;
        }
        .web-link {
            text-align: center;
            margin-top: 10px;
            font-size: 14px;
        }
        
           .inspirational-quote {
            font-style: italic;
            font-family: 'Pacifico', cursive; /* Estilo de letra similar a escritura a mano */
            text-align: center;
            margin-top: 10px;
            font-size: 20px;
        }
      </style>
        </head>
    <body onload="window.print(); window.close();">
        <div class="logo" style="text-align: center;">
               <img src="${logoImage}" alt="Logo" style="width: 30mm; height: auto;">
        </div>
        <div class="web-link">
            <label href="${websiteUrl}">${websiteUrl}</label>
            <div>
                <label>Lo mejor de la comida Cajabambina en Cajamarca</label>
            </div>
            <div>
                <label>Jr. Juan Beato Macias 482</label>
            </div>
            <div>
                <label>DELIVERY: 980415762</label>
            </div>

        </div>
        ${printContents}
    </body>
              <div class="inspirational-quote">
                <label>${fraseAleatoria}</label>
            </div>
</html>
`);
        printWindow.document.close();

        printFrame.addEventListener('load', () => {
            setTimeout(() => {
                document.body.removeChild(printFrame);
            }, 1000);
        });
    }



    return (
        <Popover
            open={isOpen}
            anchorEl={anchorEl}
            onClose={closePopover}
            anchorOrigin={{vertical: 'bottom', horizontal: 'center'}}
            transformOrigin={{vertical: 'top', horizontal: 'center'}}
            PaperProps={{
                style: {width: '500px', maxHeight: '500px', overflowY: 'auto', wordWrap: 'break-word'},
            }}
        >
            <CardHeader
                title={`Mesa ${mesaNum} Hora: ${data.hora}`}
                subheader={`Atiende: ${data.usuario}`}
                sx={{color: "#f1b979", marginBottom: "-1.5rem"}}
            />
            <CardContent id="print-area">
                <Typography variant="subtitle2">Cliente: {data.cliente}</Typography>
                <Typography variant="subtitle2">Tipo: {data.reserva}</Typography>
                <TableContainer component={Paper} sx={{marginBottom: "0.6rem"}}>
                    <Table size="small">
                        <TableHead>
                            <TableRow>
                                <TableCell style={{fontSize: '0.7rem', fontWeight: 'bold'}}>Producto</TableCell>
                                <TableCell style={{fontSize: '0.7rem', fontWeight: 'bold'}}>Cantidad</TableCell>
                                <TableCell style={{fontSize: '0.7rem', fontWeight: 'bold'}}>Tipo</TableCell>
                                <TableCell className="no-print" style={{fontSize: '0.7rem', fontWeight: 'bold'}}>Estado</TableCell>
                                <TableCell style={{fontSize: '0.7rem', fontWeight: 'bold'}}>Total</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {data.producto?.split(', ').map((producto, index) => (
                                <TableDataRow
                                    key={index}
                                    producto={producto}
                                    cantidad={data.cantidad?.split(', ')[index]}
                                    descripcion={data.descripcion?.split(', ')[index]}
                                    estado={data.estado?.split(', ')[index]}
                                    total={data.total?.split(', ')[index]}
                                />
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
                <Typography variant="title" sx={{color: "#f1b979"}}>Total Pedido: S/.  {data.totalpedido}</Typography>
                <Typography variant="subtitle2" sx={{color: "#d7424f"}}>Observaciones: {data.observaciones}</Typography>
                <Typography variant="subtitle2" sx={{color: "rgba(47,196,44,0.84)"}}>Direccion: {data.direccion}</Typography>
                <Typography variant="subtitle2" sx={{color: "#4878b5"}}>Teléfono: {data.telefono}</Typography>
            </CardContent>
            <Button variant="contained" onClick={handlePrint} sx={{ margin: 1 }}>
                Imprimir
            </Button>

            <style>
                {`
                @media print {
                    * {
                    border: 1px solid red; /* Solo para depuración; elimina esto una vez resuelto el problema */
                    }
                    @page {
                        size: 58mm !important;
                        margin: 0mm !important;
                    }
                    body {
                        margin: 0 !important;
                        padding: 0 !important;
                        width: 58mm;
                    }
                    html, body {
                        height: auto !important;
                        overflow: hidden;
                        position: relative;
                        margin: 0 !important;
                        padding: 0 !important;
                    }
                    #print-area {
                        page-break-after: avoid;
                        margin: 0 !important;
                    }
                }
                `}
            </style>
        </Popover>
    );
};

export default InfoPopover;
