import React from 'react';
import { Box, Typography, Paper } from '@mui/material';

const TimelineMesa = ({ dataOrden }) => {
    const arrowLength = `${dataOrden.length * 35}px`;

    return (
        <Box display="flex" flexDirection="column" alignItems="center" width="100%" my={1}>
            <Box display="flex" alignItems="center" width="100%" mb={1}>
                {dataOrden.map((item, index) => (
                    <Box key={index} display="flex" flexDirection="column" alignItems="center" mx={0.5}>
                        <Typography variant="body2" color="textSecondary" style={{ fontSize: '0.68rem' }}>
                            {item.hora}
                        </Typography>
                        <Paper elevation={3} style={{ padding: '2px 4px', margin: '1px 0', maxWidth: '80px' }}>
                            <Typography variant="body2" component="div" style={{ fontSize: '0.68rem' }}>
                                {item.mesa}
                            </Typography>
                        </Paper>
                    </Box>
                ))}
            </Box>
            <Box width="100%" position="relative">
                <Box
                    sx={{
                        position: 'absolute',
                        left: 0,
                        top: '50%',
                        transform: 'translateY(-50%)',
                        height: '2px',
                        backgroundColor: '#ead080',
                        width: arrowLength,
                    }}
                />
                <Box
                    sx={{
                        position: 'absolute',
                        left: `calc(${arrowLength} - 5px)`, // Ajustar para que la cabeza de la flecha esté visible al final de la línea
                        top: '50%',
                        transform: 'translateY(-50%)',
                        width: '10px',
                        height: '10px',
                        backgroundColor: '#ead080',
                        clipPath: 'polygon(0 0, 100% 50%, 0 100%)', // Cambia la forma de la flecha para que apunte a la derecha
                    }}
                />
            </Box>
        </Box>
    );
};

export default TimelineMesa;
