import React, { useState } from 'react';
import { QrReader } from 'react-qr-reader';
import { Card, CardContent, Typography, Alert, Stack } from '@mui/material';

const QRScanner = ({ onScan }) => {
    const [data, setData] = useState('');
    const [error, setError] = useState('');

    const handleResult = (result) => {
        if (result) {
            console.log('QR Code Scanned:', result);
            const qrText = result.text; // Asumiendo que 'text' es la propiedad que quieres mostrar
            setData(qrText);
            onScan(qrText); // Envía el dato escaneado al padre
        } else {
            console.log('No QR code detected.');
        }
    }


    const handleError = (err) => {
       // console.error('QR Scanning Error:', err); // Nueva declaración de consola
        setError(err.message);
    }

    return (
        <Card>
            <CardContent>
                <Typography gutterBottom variant="h5" component="div">
                    Canjear cupón QR
                </Typography>
                <QrReader
                    delay={300}
                    onError={handleError}
                    onResult={handleResult} // Actualizado aquí
                    style={{ width: '100%' }}
                />
                {data && (
                    <Typography variant="subtitle1" gutterBottom>
                        Resultado del escaneo: {data} {/* 'data' debe ser una cadena de texto, no un objeto */}
                    </Typography>
                )}
                {error && (
                    <Stack sx={{ width: '100%', marginTop: 2 }}>
                        <Alert severity="error">{error}</Alert>
                    </Stack>
                )}
            </CardContent>
        </Card>
    );
}

export default QRScanner;
