import React, { useState, useContext} from 'react';
import AppContext from '../contexts/AppContext';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import axios from 'axios';
import img from '../assets/icons/logowislla.png';
import backgroundImage from '../assets/icons/fotowislla3.jpg';
import config from "../system/config";

function Login() {

    const {darkMode } = useContext(AppContext);
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const {handleLogin} = useContext(AppContext);
    const handleSubmit = async (event) => {
        event.preventDefault();
        const payload = { emailAddress: email, password };
        try {
            const response = await axios.post(`${config.baseURL}/login`, payload);

            if (email) {
                localStorage.setItem('token', response.data.token);
                await handleLogin(true, email);
            } else {
                console.error("El correo electrónico es indefinido");
            }
        } catch (error) {
            if (error.response && error.response.status === 401) {
                console.log('Credenciales incorrectas. Por favor, intenta de nuevo.');
            } else {
                console.error('Error al iniciar sesión', error);
            }
        }
    };

    return (
        <Box display="flex" justifyContent="center" style={{
            minHeight: '100vh',
            paddingTop: '4%',
            backgroundImage: `url(${backgroundImage})`,
            backgroundSize: 'cover',
            backgroundPosition: 'center'
        }}>
            <Box sx={{ width: { xs: '98%', sm: '60%', md: '40%'}  , marginLeft: '10px'}}>
            <Typography  align="center" style={{fontSize:'30px', color: darkMode ? '#ffffff' : '#ffffff', marginBottom: '15px' }}>Acceso</Typography>
                <form autoComplete="off" onSubmit={handleSubmit}>
                    <Grid container spacing={2} direction="column" alignItems="center">
                        <Grid container>
                            <Grid item md={2}></Grid>
                            <Grid item xs={12} md={8}>
                                <TextField id="email" label="Email" fullWidth sx={{ marginTop: "20px", color: darkMode ? '#ffffff' : '#ffffff' }} value={email} onChange={e => setEmail(e.target.value)} />
                            </Grid>
                            <Grid item md={2}></Grid>
                        </Grid>
                        <Grid container>
                            <Grid item md={2}></Grid>
                            <Grid item xs={12} md={8}>
                                <TextField id="password" label="Contraseña"  fullWidth type="password" sx={{ marginTop: "20px", color: darkMode ? '#ffffff' : '#ffffff' }} value={password} onChange={e => setPassword(e.target.value)} />
                            </Grid>
                            <Grid item md={2}></Grid>
                        </Grid>
                        <Grid item>
                            <Button variant="contained" color="primary" type="submit" style={{fontSize:'small'}}>Ingresar</Button>
                        </Grid>
                    </Grid>


                    <Typography variant="body2" color="textSecondary" align="center" style={{ marginTop: "20px", color: darkMode ? '#ffffff' : '#ffffff' }}>
                        © {new Date().getFullYear()} La Wislla - Perú
                    </Typography>
                    <Typography variant="body2" color="textSecondary" align="center" style={{ marginTop: "2px", color: darkMode ? '#ffffff' : '#ffffff' }}>
                        CAJAMARCA
                    </Typography>
                    <Typography variant="body2" color="textSecondary" align="center" style={{ marginTop: "2px", fontSize:"x-small" , color: darkMode ? '#ffffff' : '#ffffff'}}>
                        Developer Har Pérez
                    </Typography>
                    <Grid item align="center">
                        <img src={img} alt="Logo" style={{ width: '10%', paddingTop:'20px' }} />
                    </Grid>

                </form>
            </Box>
        </Box>
    );
}

export default Login;
