// audioMesas.js
const audios = [
     require('../assets/audio/mp3/mesa1lista.mp3'),
     require('../assets/audio/mp3/mesa2lista.mp3'),
     require('../assets/audio/mp3/mesa3lista.mp3'),
     require('../assets/audio/mp3/mesa4lista.mp3'),
     require('../assets/audio/mp3/mesa5lista.mp3'),
     require('../assets/audio/mp3/mesa6lista.mp3'),
     require('../assets/audio/mp3/mesa7lista.mp3'),
     require('../assets/audio/mp3/mesa8lista.mp3'),
     require('../assets/audio/mp3/mesa9lista.mp3'),
     require('../assets/audio/mp3/mesa10lista.mp3'),
     require('../assets/audio/mp3/mesa11lista.mp3'),
     require('../assets/audio/mp3/mesa12lista.mp3'),
     require('../assets/audio/mp3/mesa13lista.mp3'),
     require('../assets/audio/mp3/mesa14lista.mp3'),
     require('../assets/audio/mp3/mesa15lista.mp3'),
     require('../assets/audio/mp3/mesa16lista.mp3'),
     require('../assets/audio/mp3/mesa17lista.mp3'),
     require('../assets/audio/mp3/mesa18lista.mp3'),
     require('../assets/audio/mp3/mesa19lista.mp3'),
     require('../assets/audio/mp3/mesa20lista.mp3'),
     require('../assets/audio/mp3/mesa21lista.mp3'),
     require('../assets/audio/mp3/mesa22lista.mp3'),
     require('../assets/audio/mp3/mesa23lista.mp3'),
     require('../assets/audio/mp3/mesa24lista.mp3'),
     require('../assets/audio/mp3/mesa25lista.mp3'),
     require('../assets/audio/mp3/mesa26lista.mp3'),
     require('../assets/audio/mp3/mesa27lista.mp3'),
     require('../assets/audio/mp3/mesa28lista.mp3'),
     require('../assets/audio/mp3/mesa29lista.mp3'),
     require('../assets/audio/mp3/mesa30lista.mp3'),
     require('../assets/audio/mp3/mesa31lista.mp3'),
     require('../assets/audio/mp3/mesa32lista.mp3'),
     require('../assets/audio/mp3/mesa33lista.mp3'),
     require('../assets/audio/mp3/mesa34lista.mp3'),
     require('../assets/audio/mp3/mesa35lista.mp3'),
     require('../assets/audio/mp3/mesa36lista.mp3'),
     require('../assets/audio/mp3/mesa37lista.mp3'),
     require('../assets/audio/mp3/mesa38lista.mp3'),
     require('../assets/audio/mp3/mesa39lista.mp3'),
     require('../assets/audio/mp3/mesa40lista.mp3'),
     require('../assets/audio/mp3/mesa41lista.mp3'),
     require('../assets/audio/mp3/mesa42lista.mp3'),
     require('../assets/audio/mp3/mesa43lista.mp3'),
     require('../assets/audio/mp3/mesa44lista.mp3'),
     require('../assets/audio/mp3/mesa45lista.mp3'),
     require('../assets/audio/mp3/mesa46lista.mp3'),
     require('../assets/audio/mp3/mesa47lista.mp3'),
     require('../assets/audio/mp3/mesa48lista.mp3'),
     require('../assets/audio/mp3/excesivo1.mp3'),
     require('../assets/audio/mp3/excesivo2.mp3'),
     require('../assets/audio/mp3/excesivo3.mp3'),
     require('../assets/audio/mp3/excesivo4.mp3'),
     require('../assets/audio/mp3/excesivo5.mp3'),
     require('../assets/audio/mp3/excesivo6.mp3'),
     require('../assets/audio/mp3/excesivo7.mp3'),
     require('../assets/audio/mp3/excesivo8.mp3'),
     require('../assets/audio/mp3/excesivo9.mp3'),
     require('../assets/audio/mp3/excesivo10.mp3'),
     require('../assets/audio/mp3/excesivo11.mp3'),
     require('../assets/audio/mp3/excesivo12.mp3'),
     require('../assets/audio/mp3/excesivo13.mp3'),
     require('../assets/audio/mp3/excesivo14.mp3'),
     require('../assets/audio/mp3/excesivo15.mp3'),
     require('../assets/audio/mp3/excesivo16.mp3'),
     require('../assets/audio/mp3/excesivo17.mp3'),
     require('../assets/audio/mp3/excesivo18.mp3'),
     require('../assets/audio/mp3/excesivo19.mp3'),
     require('../assets/audio/mp3/excesivo20.mp3'),
     require('../assets/audio/mp3/excesivo21.mp3'),
     require('../assets/audio/mp3/excesivo22.mp3'),
     require('../assets/audio/mp3/excesivo23.mp3'),
     require('../assets/audio/mp3/excesivo24.mp3'),
     require('../assets/audio/mp3/excesivo25.mp3'),
     require('../assets/audio/mp3/excesivo26.mp3'),
     require('../assets/audio/mp3/excesivo27.mp3'),
     require('../assets/audio/mp3/excesivo28.mp3'),
     require('../assets/audio/mp3/excesivo29.mp3'),
     require('../assets/audio/mp3/excesivo30.mp3'),
     require('../assets/audio/mp3/excesivo31.mp3'),
     require('../assets/audio/mp3/excesivo32.mp3'),
     require('../assets/audio/mp3/excesivo33.mp3'),
     require('../assets/audio/mp3/excesivo34.mp3'),
     require('../assets/audio/mp3/excesivo35.mp3'),
     require('../assets/audio/mp3/excesivo36.mp3'),
     require('../assets/audio/mp3/excesivo37.mp3'),
     require('../assets/audio/mp3/excesivo38.mp3'),
     require('../assets/audio/mp3/excesivo39.mp3'),
     require('../assets/audio/mp3/excesivo40.mp3'),
     require('../assets/audio/mp3/excesivo41.mp3'),
     require('../assets/audio/mp3/excesivo42.mp3'),
     require('../assets/audio/mp3/excesivo43.mp3'),
     require('../assets/audio/mp3/excesivo44.mp3'),
     require('../assets/audio/mp3/excesivo45.mp3'),
     require('../assets/audio/mp3/excesivo46.mp3'),
     require('../assets/audio/mp3/excesivo47.mp3'),
     require('../assets/audio/mp3/excesivo48.mp3'),
     require('../assets/audio/mp3/actualicestock.mp3')

];

export default audios;
