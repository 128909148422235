import axios from 'axios';
import config from "../system/config";

export const ConsumoDiaData = async () => {
    try {
        const response = await axios.get(`${config.baseURL}/consumodia`);
        if (response.data.status) {
            return response.data.data;
        } else {
            console.error('Error fetching data:', response.data);
        }
    } catch (error) {
        console.error('There was an error fetching the data:', error);
    }
};
