import React, { useState, useEffect } from 'react';
import axios from 'axios';
import {Table, TableBody, TableCell, TableHead, TableRow, Paper, CircularProgress, Typography} from '@mui/material';
import config from "../system/config";

const PronosticoTable = () => {
    const [data, setData] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        // Obtener datos del API
        axios.get(`${config.baseURL}/getPronostico`)
            .then(response => {
                if (response.data.status) {
                    setData(response.data.data[0]);
                    setLoading(false);
                }
            })
            .catch(error => {
                console.error('Error fetching data:', error);
                setLoading(false);
            });
    }, []);


    if (loading) return <CircularProgress />;
    if (error) return <Typography variant="h6" color="error">Error: {error}</Typography>;


    return (
        <Paper elevation={3}>
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell style={{ fontWeight: 'bold', fontSize: '1.2em' }}>
                            Producto
                        </TableCell>
                        <TableCell style={{ fontWeight: 'bold', fontSize: '1.2em' }} align="right">
                            Cantidad
                        </TableCell>
                    </TableRow>

                </TableHead>
                <TableBody>
                    {Object.keys(data).map(key => (
                        <TableRow key={key}>
                            <TableCell component="th" scope="row">
                                {key}
                            </TableCell>
                            <TableCell align="right">{data[key]}</TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </Paper>
    );
};

export default PronosticoTable;
