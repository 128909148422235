import React from 'react';
import UpdatePasswordComponent from './UpdatePasswordComponent'; // Asegúrate de que la ruta de importación sea correcta

const Cuenta = () => {
    return (
        <div style={{marginTop:'3rem'}}>
            {/* Incorporar el componente aquí */}
            <UpdatePasswordComponent />
        </div>
    );
}

export default Cuenta;
